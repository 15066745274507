import React from 'react';
import { motion } from 'framer-motion';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Line } from 'react-chartjs-2';

const PredictiveAnalytics = () => {
  const initial = 18;
  const current = 24;
  const target = 38;
  const daysPassed = 30;
  const totalDays = 90;
  const predicted = ((target - initial) / totalDays) * daysPassed + initial;

  const percentage = ((current - initial) / (target - initial)) * 100;

  const lineChartData = {
    labels: Array.from({ length: totalDays }, (_, i) => i + 1),
    datasets: [
      {
        label: 'Initial Value',
        data: Array(totalDays).fill(initial),
        borderColor: 'rgba(99, 102, 241, 0.5)',
        borderWidth: 1,
        pointRadius: 0,
      },
      {
        label: 'Current Trend',
        data: [...Array(daysPassed).fill(initial).map((val, i) => val + ((current - initial) / daysPassed) * i), ...Array(totalDays - daysPassed).fill(null)],
        borderColor: 'rgba(16, 185, 129, 0.5)',
        borderWidth: 1,
        borderDash: [5, 5],
        pointRadius: 0,
      },
      {
        label: 'Predicted Trend',
        data: Array(totalDays).fill(initial).map((val, i) => val + ((predicted - initial) / daysPassed) * i),
        borderColor: 'rgba(251, 191, 36, 0.5)',
        borderWidth: 1,
        pointRadius: 0,
      },
      {
        label: 'Target Value',
        data: Array(totalDays).fill(target),
        borderColor: 'rgba(220, 38, 38, 0.5)',
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: true,
        suggestedMin: initial - 5,
        suggestedMax: target + 5,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
    },
    elements: {
      line: {
        tension: 0.3,
      },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white p-8 rounded-3xl shadow-xl space-y-4"
    >
      <h2 className="text-3xl font-bold text-gray-800">Predictive Analytics</h2>
      <div className="flex justify-between items-center">
        <div className="w-1/4">
          <CircularProgressbar
            value={percentage}
            text={`${current}%`}
            styles={buildStyles({
              pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
              textColor: '#3e98c7',
            })}
          />
        </div>
        <div className="grid grid-cols-3 gap-4 text-center">
          {[
            { label: 'Initial value', value: `${initial}%` },
            { label: 'Current value', value: `${current}%` },
            { label: 'Target value', value: `${target}%` },
          ].map((stat, index) => (
            <motion.div
              key={index}
              className="bg-gray-100 p-4 rounded-lg shadow-sm"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <h3 className="font-semibold text-gray-700">{stat.label}</h3>
              <p className="text-lg">{stat.value}</p>
            </motion.div>
          ))}
        </div>
      </div>
      <p className="text-sm text-gray-500 mt-4">
        Goal: Increase the Engineering team's Autonomy by 20% in 90 days.
      </p>
      <div className="relative h-80 mt-6">
        <Line data={lineChartData} options={lineChartOptions} />
      </div>
    </motion.div>
  );
};

export default PredictiveAnalytics;
