import React, { useState } from "react";
import { auth } from "../../firebase";
import { getSelectedCompanyId } from "../../utils/companyStorage";
import { getApiUrl } from "../../utils/apiConfig";
import PricingCardList from "./PricingCardList";
import { itemVariants } from "./AnimationsVariants";
import { motion } from "framer-motion";
import { CircularProgress } from "@mui/material";

const Payment = ({ numberOfEmployees }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Function to handle the plan selection
  const handlePlanSelect = (id, type) => {
    setSelectedPlan({ id, type });
  };

  // Function to create a checkout session
  const createCheckoutSession = async () => {
    if (!selectedPlan) {
      alert("Please select a plan before proceeding.");
      return;
    }

    setIsLoading(true);

    try {
      console.log("Starting createCheckoutSession");

      const idToken = await auth.currentUser.getIdToken();
      const userUid = await getSelectedCompanyId(auth.currentUser.uid);

      const url = getApiUrl("customer-subscription-checkout");
      const queryParams = new URLSearchParams({
        company_uuid: userUid,
        email: auth.currentUser.email,
        number_of_employees: numberOfEmployees,
        plan_type: selectedPlan.type,
      });

      const response = await fetch(`${url}?${queryParams}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.url) {
        window.location.href = data.url;
      } else {
        console.error("Error creating checkout session:", data.error);
        alert("Failed to create checkout session");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert(`Failed to create checkout session: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      {numberOfEmployees !== "1001+" && (
        <>
          <motion.h2
            className="text-2xl font-semibold mb-4 text-center text-blue-800"
            variants={itemVariants}
          >
            Select Your Subscription Plan
          </motion.h2>
          <div className="text-lg text-center mb-4">
            <p className="mb-2">
              Congratulations! You are one step away from transforming your
              company and aligning with our mission at WorkSense. We are excited
              to help you enhance your team's productivity and efficiency.
            </p>
            <p className="font-medium">
              To proceed, please select a plan. Enjoy a 30-day free trial with
              each option.
            </p>
          </div>
        </>
      )}
      <div className="w-full">
        <PricingCardList
          numberOfEmployees={numberOfEmployees}
          onPlanSelect={handlePlanSelect}
        />
        {numberOfEmployees !== "1001+" && (
          <div className="flex justify-center mt-4">
            <motion.button
              className={`relative flex items-center justify-center px-8 py-3 bg-gradient-to-r from-blue-500 to-teal-500 text-white rounded-lg font-semibold text-lg shadow-lg hover:shadow-xl focus:shadow-xl transition duration-600 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 ${
                isLoading ? "cursor-not-allowed" : ""
              }`}
              onClick={createCheckoutSession}
              disabled={isLoading || selectedPlan === null}
              whileTap={{ scale: 0.95 }}
              style={{ width: "250px" }} // Set a fixed width for the button
            >
              {isLoading ? (
                <>
                  <span className="mr-2">Processing...</span>
                  <CircularProgress size={20} color="inherit" />
                </>
              ) : (
                "Proceed to Checkout"
              )}
            </motion.button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Payment;
