import Select from "react-select";
import LineData from "../components/LineData";
import "../index.css";
import Loader from "../utils/Loader";
import { Button } from "@mui/material";
import React, { useState, useCallback } from "react";
import { auth } from "../firebase";
import * as Constants from "../utils/Constants";
import { getFilterDatesConditions } from "../utils/DateRange";
import { getFilteredEmployeeQuery } from "../utils/FilterUtils";
import { getApiUrl } from "../utils/apiConfig";
import {
  getRandomColor,
  getCompanyAverage,
  formatDepartments,
} from "../utils/TimeSeriesUtils";
import FilterOptions from "../components/FilterOptions";
import Layout from "../containers/Layout";
import useAuthenticatedUser from "../hooks/AuthenticateUser";

export default function TimeSeries({ data, teams, handleTeamChange }) {
  const { user, sandboxUserQueryParameter, isLoading } = useAuthenticatedUser();
  const [multiValue, setMultiValue] = useState([]);
  const [additionalTeams, setAdditionalTeams] = useState(null);
  const [filters, setFilters] = useState(null);
  const [scoreTrendDayOverDayData, setScoreTrendDayOverDayData] =
    useState(null);
  const [scoreType, setScoreType] = useState("Sentiment");
  const teamColorMap = {};
  const [teamNames, setTeamNames] = useState(null);

  const handleScoreTypeChange = useCallback((newScoreType) => {
    setScoreType(newScoreType);
  }, []);

  async function getScoreTrendDayOverDay(companyId, filters, teams) {
    setScoreTrendDayOverDayData(null);
    const queryConditions = await getFilteredEmployeeQuery(companyId, filters);
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );

    const regex = /([&?])department=[^&]*(&|$)/;
    const queryConditionsCleaned = queryConditions
      .join("")
      .replace(regex, (match, p1, p2) => (p1 === "?" && p2 === "&" ? "?" : p1));
    const idToken = await auth.currentUser.getIdToken();
    const endpointUrl = getApiUrl("get-bucketed-averages");
    const url = `${endpointUrl}?company_uuid=${companyId}${queryConditionsCleaned}${filterDateConditions.join(
      ""
    )}${sandboxUserQueryParameter}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      mode: "cors",
    })
      .then((response) => response.json())
      .then((response) => {
        const labels = [
          ...new Set(
            response.map((item) => {
              const date = new Date(item["bucket_start"]);
              const formattedDate =
                date.getFullYear() +
                "-" +
                String(date.getUTCMonth() + 1).padStart(2, "0") +
                "-" +
                String(date.getUTCDate()).padStart(2, "0");
              return formattedDate;
            })
          ),
        ].sort();

        const datasets = {};
        const overallScores = Array(labels.length).fill(0);
        const countScores = Array(labels.length).fill(0);

        response.forEach((item) => {
          const date = new Date(item["bucket_start"]);
          const formattedDate =
            date.getFullYear() +
            "-" +
            String(date.getUTCMonth() + 1).padStart(2, "0") +
            "-" +
            String(date.getUTCDate()).padStart(2, "0");
          if (!datasets[item["department"]]) {
            datasets[item["department"]] = {
              label: item["department"],
              data: Array(labels.length).fill(null),
              borderColor: getColorForTeam(item["department"]),
            };
          }

          const index = labels.indexOf(formattedDate);
          if (index !== -1) {
            const avgScore =
              item["avg_" + filters.scoreType.toLowerCase() + "_score"];
            datasets[item["department"]].data[index] = avgScore || null;
            if (avgScore !== null) {
              overallScores[index] += avgScore;
              countScores[index]++;
            }
          }
        });

        const overallAverageScores = overallScores.map((total, index) => {
          return countScores[index] > 0 ? total / countScores[index] : null;
        });

        setTeamNames(Object.keys(datasets));

        let departments = [];

        if (teams && teams.length > 0) {
          departments = teams.map((team) => datasets[team]);
        }

        const departmentsFormatted = formatDepartments(departments);

        const companyAverage = getCompanyAverage(overallScores, countScores);

        departmentsFormatted.push(companyAverage);
        setScoreTrendDayOverDayData({
          labels: labels,
          datasets: departmentsFormatted,
        });
      })
      .catch((error) => {
        setScoreTrendDayOverDayData([]);
      });
  }

  const handleFilterChange = useCallback(
    (filters) => {
      const currentCompanyId = user?.uid;
      if (currentCompanyId === "aGlJ700yjLO4OtmDvx5GwvmOH5n1") {
        filters = { ...filters, location: "Belgrade" };
      }

      if (filters.scoreType && filters.scoreType !== scoreType) {
        handleScoreTypeChange(filters.scoreType);
      }

      getScoreTrendDayOverDay(currentCompanyId, filters);
      setFilters(filters);
    },
    [scoreType, handleScoreTypeChange, additionalTeams, user]
  );

  const availableTeams = teamNames
    ? teamNames.map((team) => ({
        value: team,
        label: team,
      }))
    : [];

  const handleLineChartTeamsChange = useCallback(
    async (teams) => {
      await getScoreTrendDayOverDay(user?.uid, filters, teams);
    },
    [filters, user]
  );

  const handleMultiChange = (option) => {
    setMultiValue(option);
  };

  const submitTeams = () => {
    handleLineChartTeamsChange(multiValue.map((value) => value["value"]));
  };

  function getColorForTeam(teamName) {
    if (!teamColorMap[teamName]) {
      teamColorMap[teamName] = getRandomColor();
    }
    return teamColorMap[teamName];
  }

  return (
    <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
      <Loader
        loading={isLoading}
        size={50}
        color={"#123abc"}
        loadingText={"Generating Line Chart Data"}
      >
        <FilterOptions
          user={user}
          companyUid={user?.uid}
          onFilterChange={handleFilterChange}
        />
        <div className="bg-white p-6 rounded-lg shadow-md">
          <label className="block text-gray-700 font-bold mb-2">
            Add Departments
          </label>
          <div className="mb-4">
            <Select
              name="filters"
              placeholder="Select departments..."
              value={multiValue}
              onChange={handleMultiChange}
              options={availableTeams}
              isMulti
              className="text-base"
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: "#E5E7EB",
                  borderRadius: "0.375rem",
                  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                  "&:hover": {
                    borderColor: "#D1D5DB",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#EFF6FF" : "white",
                  color: state.isSelected ? "#1E40AF" : "#374151",
                  "&:hover": {
                    backgroundColor: "#DBEAFE",
                    color: "#1E40AF",
                  },
                }),
                multiValue: (provided) => ({
                  ...provided,
                  backgroundColor: "#EFF6FF",
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: "#1E40AF",
                }),
                multiValueRemove: (provided) => ({
                  ...provided,
                  color: "#1E40AF",
                  "&:hover": {
                    backgroundColor: "#DBEAFE",
                    color: "#1E40AF",
                  },
                }),
              }}
            />
          </div>
          <Button
            variant="contained"
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
            onClick={submitTeams}
          >
            Add Departments
          </Button>
          <Loader
            loading={!scoreTrendDayOverDayData}
            componentLoading={true}
            size={30}
            color={"#3B82F6"}
            loadingText={"Loading Time Series Data"}
          >
            <LineData
              title="Department Sentiment Comparison - Day Over Day"
              dataSet={scoreTrendDayOverDayData || []}
            />
            {/* Insert Tables Here */}
            <div className="h-16"></div>
          </Loader>
        </div>
      </Loader>
    </Layout>
  );
}
