import React from "react";

const PricingCard = ({
  id,
  employees,
  price,
  annualPrice,
  type,
  isSelected,
  onSelect,
}) => {
  // Function to parse annualPrice and extract the numeric value
  const parseAnnualPrice = (priceStr) => {
    // Remove '$' and ',' and convert to number
    const numberStr = priceStr.replace(/[^0-9.]/g, "");
    return Number(numberStr);
  };

  // Extract and convert annualPrice to a number
  const annualPriceNumber = parseAnnualPrice(annualPrice);

  // Calculate monthly price if annualPrice is a valid number
  const monthlyPrice =
    !isNaN(annualPriceNumber) && annualPriceNumber > 0
      ? (annualPriceNumber / 12).toFixed(2)
      : "N/A";

  return (
    <div
      className={`relative border-2 border-blue-500 rounded-lg p-6 w-48 h-48 transition-transform duration-300 ease-in-out ${
        isSelected ? "border-3 shadow-md border-cyan-500 shadow-blue-500" : "shadow-sm"
      } hover:scale-105 bg-transparent cursor-pointer`}
      onClick={() => onSelect(id, type)}
    >
      <h2 className="text-xl font-semibold mb-2 text-center -translate-y-3">{type}</h2>
      {type === "Annually" && (
        <>
          <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 px-3.5 py-1.5 bg-yellow-500 text-white text-xs font-semibold rounded-lg">
            Best Deal
          </div>
          <div className="absolute top-10 left-1/2 transform -translate-x-1/2 w-36 py-1 bg-yellow-500 text-white text-xs font-semibold rounded-lg text-center opacity-90">
  20% off
</div>
          <p className="text-lg mb-2 text-center mt-4">
            {`$${monthlyPrice}/month, billed annually.`}
          </p>
          <p className="text-sm text-gray-600 text-center">
            {`${annualPrice}`}
          </p>
        </>
      )}

      {type === "Monthly" && (
        <p className="text-lg mb-2 text-center">{`$${monthlyPrice}/month`}</p>
      )}
    </div>
  );
};

export default PricingCard;
