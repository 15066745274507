import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

function BarData({ title, dataSet, color }) {
  const firstDataPoint = dataSet[0] || {};
  const labelKey = Object.keys(firstDataPoint)[0] || "";

  const chartData = {
    labels: dataSet.map((data) => data[labelKey]),
    datasets: [
      {
        label: "Overall Score",
        data: dataSet.map((data) => Math.round(data.average)),
        backgroundColor: color,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 16,
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        },
      },
      datalabels: {
        color: "#fff",
        anchor: "end",
        align: "right",
        formatter: Math.round,
        font: {
          weight: "bold",
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Average Score",
          font: {
            size: 14,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          },
        },
        ticks: {
          font: {
            size: 12,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          },
        },
      },
      y: {
        title: {
          display: true,
          text: labelKey,
          font: {
            size: 14,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          },
        },
        ticks: {
          font: {
            size: 12,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          },
        },
      },
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 h-full">
      <h2 className="text-xl font-semibold mb-4 text-center">{title}</h2>
      <div className="h-[calc(100%-2rem)]">
        <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />
      </div>
    </div>
  );
}

export default BarData;
