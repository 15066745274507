import React from 'react'
import { PencilIcon } from "@heroicons/react/24/outline";
import { readOnlyStyle } from "../../styles/ProfileStyles";

const LanguagePreferences = ({ isEditing, activeSection, handleEditClick }) => {
  const isSectionActive = activeSection === "languagePreferences";
  const isReadOnly = !isSectionActive;

  return (
    <div
      className={`bg-white shadow-lg rounded-lg p-6 mb-6 relative ${
        isSectionActive ? "editable" : ""
      }`}
    >
      <h2 className="text-lg font-semibold text-gray-800 mb-3">
        Language Preferences
      </h2>
      <PencilIcon
        className="absolute top-2 right-2 cursor-pointer text-gray-500 hover:text-gray-700 h-6 w-6"
        onClick={() => handleEditClick("languagePreferences")}
      />
      <div className="mb-6">
        <div className="mb-4">
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mt-4">
            <div className="flex flex-col md:flex-row md:items-center md:flex-grow">
              <label htmlFor="preferredLanguage" className="label md:mr-2">
                Preferred Language
              </label>
              <select
                className="input input-bordered w-full md:flex-grow"
                id="preferredLanguage"
                readOnly={isReadOnly}
                style={isReadOnly ? readOnlyStyle : {}}
                disabled={isReadOnly}
              >
                <option value="English">English</option>
                <option value="Arabic">Arabic</option>
                <option value="Spanish">Spanish</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguagePreferences;