import React from "react";

const ProgressBar = ({ progressWidth }) => {
  return (
    <div className="relative w-full bg-gray-200 rounded-full h-3">
      <div
        className="absolute top-0 left-0 h-full rounded-full"
        style={{
          width: `${progressWidth}%`,
          background: "linear-gradient(to right, #2196f3, #14b8a6)", // Gradient color
          transition: "width 0.3s ease-in-out",
        }}
      />
    </div>
  );
};

export default ProgressBar;
