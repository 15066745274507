import React, { useState, useCallback } from "react";
import Loader from "../utils/Loader";
import HeatMap from "../components/HeatMap";
import HeatMapKey from "../components/GenericDashboardComponents/HeatMapKey";
import TitleCard from "../components/Cards/TitleCard";
import { FaSearch } from "react-icons/fa";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { auth } from "../firebase";
import { getFilteredEmployeeQuery } from "../utils/FilterUtils";
import { getFilterDatesConditions } from "../utils/DateRange";
import { getApiUrl } from "../utils/apiConfig";
import Layout from "../containers/Layout";
import FilterOptions from "../components/FilterOptions";
import useAuthenticatedUser from "../hooks/AuthenticateUser";

export default function HeatMapView({ data }) {
  const { user, sandboxUserQueryParameter, isLoading } = useAuthenticatedUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [viewMode, setViewMode] = useState("absolute");
  const [filters, setFilters] = useState(null);
  const [heatmapData, setHeatmapData] = useState(null);
  const [scoreType, setScoreType] = useState("Sentiment");

  const handleScoreTypeChange = useCallback((newScoreType) => {
    setScoreType(newScoreType);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0); // Reset to the first page when search term changes
  };

  async function getHeatmapData(companyId, filters) {
    setHeatmapData(null);

    const queryConditions = await getFilteredEmployeeQuery(companyId, filters);
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );

    try {
      const idToken = await auth.currentUser.getIdToken();
      const endpointUrl = getApiUrl("get-department-scores");
      const url = `${endpointUrl}?company_uuid=${companyId}${queryConditions.join(
        ""
      )}${filterDateConditions.join("")}${sandboxUserQueryParameter}`;

      const heatmapResponse = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      const response = await heatmapResponse.json();
      setHeatmapData(response);
    } catch (error) {
      setHeatmapData(null);
    }
  }

  const handleFilterChange = useCallback(
    (filters) => {
      const currentCompanyId = user?.uid;
      if (currentCompanyId === "aGlJ700yjLO4OtmDvx5GwvmOH5n1") {
        filters = { ...filters, location: "Belgrade" };
      }

      if (filters.scoreType && filters.scoreType !== scoreType) {
        handleScoreTypeChange(filters.scoreType);
      }

      getHeatmapData(currentCompanyId, filters);
      setFilters(filters);
    },
    [scoreType, handleScoreTypeChange, user]
  );

  return (
    <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
      <Loader
        loading={isLoading}
        size={50}
        color={"#123abc"}
        loadingText={"Generating Heat Map"}
      >
        <FilterOptions
          user={user}
          companyUid={user?.uid}
          onFilterChange={handleFilterChange}
        />
        <TitleCard title={"Company Heatmap"}>
          <div className="sticky top-0 z-10">
            <div className="flex justify-between items-center p-4">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <FaSearch className="text-gray-500" />
                </div>
                <input
                  type="text"
                  id="search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 pl-10 p-2.5"
                  placeholder="Search departments..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <HeatMapKey viewMode={viewMode} />
            </div>
            <div className="flex items-center space-x-4 mt-4">
              <Toggle
                checked={viewMode === "differential"}
                onChange={() =>
                  setViewMode(
                    viewMode === "absolute" ? "differential" : "absolute"
                  )
                }
                icons={false}
                className="react-toggle-custom"
              />
              <span className="text-gray-700 font-medium">
                {viewMode === "absolute"
                  ? "Absolute Scores"
                  : "Differential compared to company average"}
              </span>
            </div>
          </div>

          <div className="mt-4">
            <HeatMap
              data={heatmapData}
              searchTerm={searchTerm}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              viewMode={viewMode}
            />
          </div>
        </TitleCard>
      </Loader>
    </Layout>
  );
}
