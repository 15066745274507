export function getDepartmentWithHighestScoreProportion(data) {
  if (!data || !data.departments || data.departments.length === 0) {
    return { department: null, proportion: 0, scoreProportions: {} };
  }

  let highestProportionDepartment = null;
  let highestProportion = 0;
  let highestScoreProportions = {};

  data.departments.forEach((dept) => {
    const totalEmployees = dept.total_employees_count;
    if (totalEmployees === 0) return; // Skip departments with no employees

    let departmentProportion = 0;
    let departmentScoreProportions = {};

    // Calculate the sum of proportions for all score types
    Object.entries(dept.score_counts).forEach(([scoreType, count]) => {
      const proportion = count / totalEmployees;
      departmentProportion += proportion;
      departmentScoreProportions[scoreType] = proportion;
    });

    // Update the highest proportion if this department has a higher sum
    if (departmentProportion > highestProportion) {
      highestProportion = departmentProportion;
      highestProportionDepartment = dept.department;
      highestScoreProportions = departmentScoreProportions;
    }
  });

  return {
    department: highestProportionDepartment,
    proportion: highestProportion,
    scoreProportions: highestScoreProportions,
  };
}
