import React from "react";
import TitleCard from "../Cards/TitleCard";
import performerPayWall from "../../images/performerPayWall.PNG";
import { IoIosLock } from "react-icons/io";
import Layout from "../../containers/Layout";

export default function PerformerPayWall({ userTier, displayName, email }) {
  let upgradeText = "Pro";

  if (userTier === "Pro") {
    upgradeText = "Elite";
  }

  return (
    <Layout userDisplayName={displayName} userEmail={email}>
      <TitleCard title={"Real-Time Performers"}>
        <div className="relative flex justify-center items-center h-full">
          <img
            src={performerPayWall}
            className="rounded-2xl shadow-lg blur-sm"
          />
          <a
            href="https://cal.com/arshanahmad/30min"
            className="absolute flex flex-col items-center z-10 group"
          >
            <IoIosLock className="text-gray-400 text-8xl group-hover:text-gray-600" />
            <p className="text-gray-400 text-center text-4xl mt-2 group-hover:text-gray-600">
              Upgrade to {upgradeText} <br />
              Contact Worksense
            </p>
          </a>
        </div>
      </TitleCard>
    </Layout>
  );
}
