import React, { useEffect, useState } from "react";
import {
  FaRobot,
  FaInfoCircle,
  FaUsers,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { mapScoreTypes } from "../utils/MapFunctions";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import "../index.css";
import { getApiUrl } from "../utils/apiConfig";
import * as Constants from "../utils/Constants";
import { getSelectedCompanyId } from "../utils/companyStorage";
import validatePayment from "../utils/ValidatePayment";
import { checkSandBoxStatus } from "../utils/checkSandboxStatus";
import Layout from "../containers/Layout";
import Loader from "../utils/Loader";
import useAuthenticatedUser from "../hooks/AuthenticateUser";

const RecommendationItem = ({ recommendation }) => {
  const [expanded, setExpanded] = useState(false);

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "bg-red-500 text-white";
      case "Medium":
        return "bg-yellow-500 text-white";
      case "Low":
        return "bg-green-500 text-white";
      default:
        return "bg-gray-500 text-white";
    }
  };

  return (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden mb-4 cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg border border-blue-100"
      onClick={() => setExpanded(!expanded)}
    >
      <div className="px-6 py-4">
        <div className="flex justify-between items-center mb-2">
          <h5 className="text-lg font-semibold text-gray-800">
            {recommendation.departments[0]}
          </h5>
          <span
            className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${getPriorityColor(
              recommendation.priority
            )}`}
          >
            {recommendation.priority}
          </span>
        </div>
        <p className="text-base text-gray-700 font-medium">
          {recommendation.action}
        </p>
      </div>
      {expanded && (
        <div className="px-6 py-4 bg-gray-50">
          <div className="mb-6">
            <h5 className="text-lg font-semibold mb-2 text-gray-800">
              Recommendation Details
            </h5>
            <div className="bg-blue-50 p-6 rounded-lg shadow-md border-2 border-blue-200">
              <p className="text-base text-gray-700 mb-4 font-medium">
                {recommendation.detail}
              </p>
              <ul className="list-disc list-inside text-gray-600">
                {recommendation.actionSteps.map((step, index) => (
                  <li key={index} className="mb-2">
                    {step}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mb-6">
            <h5 className="text-base font-semibold mb-2 flex items-center text-gray-700">
              <Tooltip
                title="Key dimensions that influenced this recommendation"
                position="top"
              >
                <span className="flex items-center">
                  <FaInfoCircle className="mr-2 text-blue-500" />
                  Key Dimensions
                </span>
              </Tooltip>
            </h5>
            <div className="flex flex-wrap">
              {recommendation.labels.map((label) => (
                <span
                  key={label}
                  className="inline-block bg-blue-100 rounded-full px-3 py-1 text-sm font-semibold text-blue-800 mr-2 mb-2"
                >
                  {label}
                </span>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <h5 className="text-base font-semibold mb-2 flex items-center text-gray-700">
              <Tooltip
                title="Scope of applicability for this recommendation"
                position="top"
              >
                <span className="flex items-center">
                  <FaUsers className="mr-2 text-blue-500" />
                  Applicable To
                </span>
              </Tooltip>
            </h5>
            <div className="flex flex-wrap">
              {recommendation.departments.map((department) => (
                <span
                  key={department}
                  className="inline-block bg-blue-100 rounded-full px-3 py-1 text-sm font-semibold text-blue-800 mr-2 mb-2"
                >
                  {department}
                </span>
              ))}
              {recommendation.teams.map((team) => (
                <span
                  key={team}
                  className="inline-block bg-green-100 rounded-full px-3 py-1 text-sm font-semibold text-green-800 mr-2 mb-2"
                >
                  {team}
                </span>
              ))}
              {recommendation.demographics.map((demographic) => (
                <span
                  key={demographic}
                  className="inline-block bg-purple-100 rounded-full px-3 py-1 text-sm font-semibold text-purple-800 mr-2 mb-2"
                >
                  {demographic}
                </span>
              ))}
            </div>
          </div>
          <p className="text-xs text-gray-500 italic">
            *This recommendation is based on insights from experienced HR
            advisors.
          </p>
        </div>
      )}
    </div>
  );
};

const DimensionGroup = ({ dimension, recommendations }) => {
  const [expanded, setExpanded] = useState(true);

  const sortedRecommendations = recommendations.sort((a, b) => {
    const priorityOrder = { High: 0, Medium: 1, Low: 2 };
    return priorityOrder[a.priority] - priorityOrder[b.priority];
  });

  return (
    <div className="mb-8">
      <div
        className="flex justify-between items-center mb-4 cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <h3 className="text-2xl font-bold text-gray-800">{dimension}</h3>
        {expanded ? (
          <FaChevronUp className="text-gray-500" />
        ) : (
          <FaChevronDown className="text-gray-500" />
        )}
      </div>
      {expanded && (
        <div className="space-y-4">
          {sortedRecommendations.map((recommendation, index) => (
            <RecommendationItem
              key={`${recommendation.id}-${index}`}
              recommendation={recommendation}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Recommendations = () => {
  const { user, isLoading } = useAuthenticatedUser();
  const [groupedRecommendations, setGroupedRecommendations] = useState({});
  const [recommendationsMap, setRecommendationsMap] = useState([]);
  const [currentCompanyUid, setCurrentCompanyUid] = useState(null);
  const [sandboxUserQueryParameter, setSandboxUserQueryParameter] =
    useState("");

  useEffect(() => {
    if (user) {
      const fetchSelectedCompanyId = async () => {
        const companyId = await getSelectedCompanyId(user.uid);
        setCurrentCompanyUid(companyId);

        const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, companyId);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();
        setSandboxUserQueryParameter(
          checkSandBoxStatus(companyId) ? "&demo=True" : ""
        );
      };
      fetchSelectedCompanyId();
    }
  }, [user]);

  useEffect(() => {
    async function populateRecommendations() {
      if (currentCompanyUid && user) {
        const persistedRecommendations = await getRecommendationsMap();
        if (persistedRecommendations) {
          setRecommendationsMap(persistedRecommendations);
        } else {
          await getRecommendations();
        }
      }
    }
    populateRecommendations();
  }, [currentCompanyUid, user, sandboxUserQueryParameter]);

  useEffect(() => {
    async function checkPaymentStatus() {
      if (currentCompanyUid && user) {
        const hasPaid = await validatePayment(user.uid);
        if (!hasPaid) {
          window.location.href = "/onboarding";
        }
      }
    }
    checkPaymentStatus();
  }, [currentCompanyUid, user]);

  async function getRecommendations() {
    try {
      const endpointUrl = getApiUrl("get-recommendation-departments");
      const url = `${endpointUrl}?company_uuid=${currentCompanyUid}${sandboxUserQueryParameter}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        mode: "cors",
      });
      const responseData = await response.json();
      setRecommendationsMap(responseData);
    } catch (error) {
      setRecommendationsMap([]);
      console.error("Error fetching recommendations:", error);
    }
  }

  async function getRecommendationsMap() {
    const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, currentCompanyUid);
    return getDoc(userDocRef)
      .then((userDocSnapshot) => {
        return userDocSnapshot.data().recommendationScores;
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }

  useEffect(() => {
    if (recommendationsMap && recommendationsMap.length > 0) {
      const recommendationsBuilder = {};

      recommendationsMap.forEach((element) => {
        const department = element.department;
        const departmentRecommendations = element.recommendations;
        departmentRecommendations.forEach((rec) => {
          const labels = rec.labels;
          const action = rec.text;
          const id = rec.scoreType;
          let dimension = rec.type;
          const actionSteps = rec.action_steps;
          const score = rec.score;

          if (dimension.endsWith(" Score")) {
            dimension = dimension.slice(0, -6);
          }

          const recommendationObject = {
            priority: score < 30 ? "High" : score <= 40 ? "Medium" : "Low",
            dimension: dimension,
            action: action,
            id: id,
            labels: processLabels(labels),
            departments: [department],
            teams: [department],
            demographics: ["All Genders", "All Locations", "All Ethnicities"],
            confidence: Math.floor(Math.random() * 19) + 82,
            actionSteps: actionSteps,
            detail: rec.detail || "Details not provided.",
          };

          if (!recommendationsBuilder[dimension]) {
            recommendationsBuilder[dimension] = [];
          }
          recommendationsBuilder[dimension].push(recommendationObject);
        });
      });

      setGroupedRecommendations(recommendationsBuilder);
    }
  }, [recommendationsMap]);

  function processLabels(labels) {
    return labels.split(",").map((label) => mapScoreTypes(label.trim()));
  }

  const sortedDimensions = Object.keys(groupedRecommendations).sort((a, b) =>
    a.localeCompare(b)
  );

  return (
    <>
      <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
        <Loader
          loading={isLoading}
          size={50}
          color={"#123abc"}
          loadingText={"Generating key actions"}
        >
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-yellow-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  This feature is currently in beta.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-3xl shadow-2xl p-10 mt-8">
            <div className="flex items-center justify-between mb-8">
              <h2 className="text-4xl font-bold text-gray-800">
                Actionable Recommendations
              </h2>
              <FaRobot className="text-4xl text-blue-500" />
            </div>
            <p className="text-gray-700 text-lg font-medium mb-8">
              Tailored strategies to enhance team dynamics and individual
              satisfaction across key dimensions.
            </p>
            {Object.keys(groupedRecommendations).length === 0 ? (
              <p className="text-green-500 font-bold text-xl">
                No actions as of now! Good job!
              </p>
            ) : (
              <div className="space-y-8">
                {sortedDimensions.map((dimension) => (
                  <DimensionGroup
                    key={dimension}
                    dimension={dimension}
                    recommendations={groupedRecommendations[dimension]}
                  />
                ))}
              </div>
            )}
          </div>
        </Loader>
      </Layout>
    </>
  );
};

export default Recommendations;
