import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { DNA } from "react-loader-spinner";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = ({ loading, children, size = 50, color = "#123abc", loadingText = "Loading" }) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [loading]);

  return loading ? (
    <div className="flex flex-col justify-left items-center h-full">
      <DNA css={loaderCSS} size={size} color={color} loading={loading} />
      <div className="mt-2 text-center" style={{ color }}>
        {loadingText}{dots}
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

export default Loader;