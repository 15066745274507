import React, { useEffect, useState } from "react";
import { FORMAT_NUMBER } from "../../utils/Constants";
import TotalMessagesCard from "../Cards/TotalMessagesCard";
import Loader from "../../utils/Loader";
import { auth } from "../../firebase";
import { getApiUrl } from "../../utils/apiConfig";

function MessagesComponent({ userUid, isLoading }) {
  const [totalMessages, setTotalMessages] = useState(null);
  const [processedMessages, setProcessedMessages] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getTotalMessages = async () => {
      if (!userUid) return;
      setIsFetching(true);

      const idToken = await auth.currentUser.getIdToken();
      const endpointUrl = getApiUrl("get-total-messages");
      const url = `${endpointUrl}?company_uuid=${userUid}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
          mode: "cors",
        });

        const data = await response.json();
        setTotalMessages(data["total_rows"]);
        setProcessedMessages(data["processed_rows"]);
      } catch (error) {
        console.error("Error fetching total messages:", error);
        setTotalMessages(null);
        setProcessedMessages(null);
      } finally {
        setIsFetching(false);
      }
    };

    getTotalMessages();
  }, [userUid]);

  return (
    <Loader loading={isLoading || isFetching} size={50} color={"#123abc"}>
      {!isLoading &&
        !isFetching &&
        totalMessages !== null &&
        processedMessages !== null && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <TotalMessagesCard
              title="Total Messages Analyzed"
              value={
                processedMessages ? FORMAT_NUMBER(processedMessages) : "N/A"
              }
            />
            <TotalMessagesCard
              title="Total Messages Count"
              value={totalMessages ? FORMAT_NUMBER(totalMessages) : "N/A"}
            />
          </div>
        )}
    </Loader>
  );
}

export default MessagesComponent;
