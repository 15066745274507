import React from 'react'
import { PencilIcon } from "@heroicons/react/24/outline";
import { readOnlyStyle } from "../../styles/ProfileStyles";

const CompanyInfo = ({ userEmail, name, domain, numberOfEmployees, isEditing, activeSection, handleEditClick }) => {
  const isSectionActive = activeSection === "profileInformation";
  const isReadOnly = !isSectionActive;

  return (
    <div className={`bg-white shadow-lg rounded-lg p-6 mb-6 relative ${isSectionActive ? "editable" : ""}`}>
      <h2 className="text-lg font-semibold text-gray-800 mb-3">Company Information</h2>
      <PencilIcon
        className="absolute top-2 right-2 cursor-pointer text-gray-500 hover:text-gray-700 h-6 w-6"
        onClick={() => handleEditClick("profileInformation")}
      />
      <div className="mb-6">
        <div className="mb-4">
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mt-4">
            <div className="flex flex-col md:flex-row md:items-center md:flex-grow">
              <label htmlFor="companyName" className="label md:mr-2">Name</label>
              <input
                className="input input-bordered w-full md:flex-grow"
                type="text"
                id="companyName"
                value={name}
                readOnly={isReadOnly}
                style={isReadOnly ? readOnlyStyle : {}}
              />
            </div>
            <div className="flex flex-col md:flex-row md:items-center md:flex-grow">
              <label htmlFor="companySize" className="label md:mr-2">Size</label>
              <select
                className="input input-bordered w-full md:flex-grow"
                id="companySize"
                value={numberOfEmployees}
                readOnly={isReadOnly}
                style={isReadOnly ? readOnlyStyle : {}}
                disabled={isReadOnly}
              >
                <option value="1-10">1-10 employees</option>
                <option value="11-50">11-50 employees</option>
                <option value="51-200">51-200 employees</option>
                <option value="201-500">201-500 employees</option>
                <option value="501+">501-1,000 employees</option>
                <option value="1001-5000">1,001-5,000 employees</option>
                <option value="5001-10000">5,001-1,0000 employees</option>
                <option value="10001+">10,001+ employees</option>
              </select>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="emailID" className="label">Email ID</label>
          <input
            className="input input-bordered w-full"
            type="text"
            id="emailID"
            value={userEmail}
            readOnly={isReadOnly}
            style={readOnlyStyle}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="companyDomain" className="label">Domain</label>
          <input
            className="input input-bordered w-full"
            type="text"
            id="companyDomain"
            value={domain}
            readOnly={isReadOnly}
            style={readOnlyStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;