import React from "react";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log("User signed out successfully"); 
      navigate("/signin"); 
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <IconButton
      color="secondary"
      onClick={handleLogout}
      aria-label="Logout"
      style={{ color: "#ffffff" }} // Set the color to white
      title="Logout"
    >  
      <ExitToAppIcon />
      <span style={{ fontSize: "14px", color: "#ffffff", marginLeft: "8px" }}>Log out</span>
      </IconButton>
  );
};

export default LogoutButton;
