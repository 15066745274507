import React from "react";
import { motion } from "framer-motion";
import { FaChartLine, FaExclamationTriangle, FaLightbulb, FaPiggyBank } from "react-icons/fa";
import productImage1 from "../images/productImage1.png";
import productImage2 from "../images/productImage2.png";
import productImage3 from "../images/productImage3.png";
import productImage4 from "../images/productImage4.png";

import { INTEGRATION_ICONS, SLACK, TEAMS, ZOOM, GMAIL } from "../utils/Constants";

const ProductPage = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const features = [
    {
      icon: <FaChartLine size={48} className="text-blue-600" />,
      title: "Real-time Sentiment Analysis",
      description: "Gain instant insights into employee sentiment without surveys, enabling proactive management and improved workplace dynamics."
    },
    {
      icon: <FaExclamationTriangle size={48} className="text-yellow-500" />,
      title: "Early Problem Detection",
      description: "Identify potential issues before they escalate, allowing for timely interventions and maintaining a positive work environment."
    },
    {
      icon: <FaLightbulb size={48} className="text-green-500" />,
      title: "AI-Driven Recommendations",
      description: "Receive tailored suggestions for improving employee satisfaction and engagement based on advanced data analysis."
    },
    {
      icon: <FaPiggyBank size={48} className="text-purple-500" />,
      title: "Cost-Effective Solution",
      description: "Eliminate the need for expensive and time-consuming surveys while gaining more accurate and timely insights."
    }
  ];

  const howItWorks = [
    {
      title: "Seamless Integration",
      description: "WorkSense integrates effortlessly with your existing communication tools like Slack, Microsoft Teams, Zoom, and Gmail. Our secure API ensures a smooth connection without disrupting your workflow.",
      image: (
        <div className="flex justify-center items-center space-x-4">
          <img src={INTEGRATION_ICONS[SLACK]} alt="Slack" className="w-12 h-12" />
          <img src={INTEGRATION_ICONS[TEAMS]} alt="Microsoft Teams" className="w-12 h-12" />
          <img src={INTEGRATION_ICONS[ZOOM]} alt="Zoom" className="w-12 h-12" />
          <img src={INTEGRATION_ICONS[GMAIL]} alt="Gmail" className="w-12 h-12" />
        </div>
      ),
    },
    {
      title: "Intelligent Data Collection & Analysis",
      description: "Our AI-powered system continuously analyzes communication patterns, content, and metadata across your integrated platforms. WorkSense uses state-of-the-art natural language processing and machine learning algorithms to accurately gauge employee sentiment.",
      image: productImage2,
    },
    {
      title: "Real-time Reporting & Alerts",
      description: "Access intuitive dashboards that visualize sentiment trends, potential issues, and team dynamics. Set up custom alerts to be notified of significant changes or concerning patterns immediately.",
      image: productImage1,
    },
    {
      title: "AI-Driven Recommendations & Improvement Tracking",
      description: "Receive tailored suggestions for improving workplace dynamics based on your unique data. Monitor the impact of your interventions over time, tracking improvements in sentiment, engagement, and productivity.",
      image: productImage3,
    },
    {
      title: "Unlock Significant Cost Savings",
      description: "Experience substantial cost savings across your organization. Boost operational efficiency, reduce turnover, optimize resource allocation, and increase productivity, all leading to a measurable return on investment.",
      image: productImage4,
    },
  ];

  return (
    <div className="bg-gradient-to-b from-cyan-500 to-blue-500 min-h-screen text-white">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <motion.h1
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          className="text-5xl font-extrabold mb-8 text-center"
        >
          WorkSense: AI-Powered Employee Sentiment Analysis
        </motion.h1>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          className="text-xl mb-12 text-center max-w-3xl mx-auto"
        >
          Revolutionize your workplace with real-time sentiment analysis, actionable insights, and AI-driven recommendations.
        </motion.p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ delay: index * 0.2 }}
              className="bg-white text-blue-900 p-6 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-4">
                {feature.icon}
                <h2 className="text-2xl font-bold ml-4">{feature.title}</h2>
              </div>
              <p className="text-lg">{feature.description}</p>
            </motion.div>
          ))}
        </div>

        {/* Mid-Page Call to Action Section */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          className="relative mb-16 flex justify-center items-center flex-col bg-white text-blue-900 py-10 rounded-lg shadow-xl text-center"
        >
          <div className="relative z-10">
            <h2 className="text-3xl font-bold mb-8">
              Take the Next Step
            </h2>
            <p className="text-xl mb-8">
            Discover how Worksense can revolutionize your organization's employee engagement.
            </p>
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-block px-12 py-6 font-semibold text-xl text-white bg-gradient-to-r from-blue-700 to-blue-900 hover:bg-gradient-to-l focus:outline-none focus:ring-4 focus:ring-blue-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1"
              href="/signin"
              aria-label="Get Started with WorkSense"
            >
              Get Started with WorkSense
            </motion.a>
          </div>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          className="bg-white text-blue-900 p-8 rounded-lg shadow-xl mb-12"
        >
          <h2 className="text-3xl font-bold mb-6 text-center">How WorkSense Transforms Your Workplace</h2>
          
          {howItWorks.map((step, index) => (
            <motion.div
              key={index}
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ delay: index * 0.2 }}
              className="mb-12 flex flex-col md:flex-row items-center"
            >
              <div className="md:w-1/2 md:pr-8 mb-6 md:mb-0">
                <h3 className="text-2xl font-bold mb-4">{index + 1}. {step.title}</h3>
                <p className="text-lg">{step.description}</p>
              </div>
              <div className="md:w-1/2 flex justify-center">
                {typeof step.image === 'string' ? (
                  <img src={step.image} alt={step.title} className="rounded-lg shadow-md max-w-full h-auto" />
                ) : (
                  step.image
                )}
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* Bottom Call to Action Section */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          className="text-center"
        >
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Workplace?</h2>
          <a
            href="/signin"
            className="inline-block bg-white text-blue-900 px-8 py-4 rounded-lg font-bold text-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:bg-blue-100"
          >
            Get Started with WorkSense
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default ProductPage;
