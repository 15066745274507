import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BsGraphUp, BsCashStack } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import { AiFillDollarCircle } from "react-icons/ai";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import Layout from "../containers/Layout";
import { checkAuth } from "../utils/AuthCheck";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const CostSavings = () => {
  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isDemoUser, setIsDemoUser] = useState(true);
  const [authComplete, setAuthComplete] = useState(false);
  useEffect(() => {
    async function authenticateUser() {
      try {
        await checkAuth(setUser, setUserToken, setIsDemoUser);
        setAuthComplete(true);
      } catch (error) {
        console.error("Error during authentication:", error);
      }
    }

    authenticateUser();
  }, []);
  const costSavingsData = [
    {
      icon: <BsGraphUp size={32} className="text-green-600" />,
      title: "Overall Savings",
      value: "$1.53M",
      description:
        "Total savings realized this fiscal year due to increased efficiency.",
    },
    {
      icon: <BsCashStack size={32} className="text-blue-600" />,
      title: "Operational Efficiency",
      value: "$800K",
      description: "Reduction in operational costs from streamlined processes.",
    },
    {
      icon: <GiTakeMyMoney size={32} className="text-yellow-500" />,
      title: "Attrition Reduction",
      value: "$400K",
      description:
        "Savings from lower turnover and reduced recruitment expenses.",
    },
    {
      icon: <AiFillDollarCircle size={32} className="text-purple-600" />,
      title: "Productivity Gains",
      value: "$330K",
      description:
        "Increased output per employee has led to higher revenue generation.",
    },
  ];

  const savingsTrendData = {
    labels: ["Q1", "Q2", "Q3", "Q4"],
    datasets: [
      {
        label: "Cost Savings",
        data: [200000, 350000, 550000, 1530000],
        fill: true,
        backgroundColor: "rgba(173, 216, 230, 0.2)",
        borderColor: "rgba(30, 144, 255, 1)",
        pointBackgroundColor: "rgba(30, 144, 255, 1)",
        pointBorderColor: "#fff",
        tension: 0.4,
      },
    ],
  };

  const trendOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => {
            if (window.innerWidth < 768) {
              return `$${value / 1000}K`;
            }
            return `$${value.toLocaleString()}`;
          },
          font: {
            size: window.innerWidth < 768 ? 10 : 12,
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: window.innerWidth < 768 ? 10 : 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            size: window.innerWidth < 768 ? 10 : 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `$${context.parsed.y.toLocaleString()}`,
        },
      },
    },
  };

  return (
    <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
      <div className="space-y-4 p-4 bg-white rounded-xl shadow-xl">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center text-gray-800">
          Cost Savings Summary
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {costSavingsData.map((data, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center p-3 sm:p-4 bg-gradient-to-tl from-gray-50 to-gray-200 rounded-lg shadow"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="text-3xl sm:text-4xl mb-2">{data.icon}</div>
              <h3 className="text-base sm:text-lg font-semibold text-center">
                {data.title}
              </h3>
              <p className="text-xl sm:text-2xl font-bold my-1">{data.value}</p>
              <p className="text-xs sm:text-sm text-gray-600 text-center">
                {data.description}
              </p>
            </motion.div>
          ))}
        </div>
        <div className="mt-6">
          <h3 className="text-lg sm:text-xl font-semibold mb-3 text-center text-gray-800">
            Quarterly Cost Savings Trend
          </h3>
          <div className="w-full h-60 sm:h-72 md:h-96">
            <Line data={savingsTrendData} options={trendOptions} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CostSavings;
