import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  FaInfoCircle,
  FaTimes,
  FaUsers,
  FaBuilding,
  FaExclamationTriangle,
  FaLightbulb,
  FaChartLine,
  FaExternalLinkAlt,
} from "react-icons/fa";
import { IoMdHappy, IoMdSad } from "react-icons/io";
import { MdSentimentNeutral } from "react-icons/md";
import ScoreTable from "../ScoreTable";
import { getDepartmentWithHighestScoreProportion } from "../../utils/EmployeeDistributionUtils";
import Loader from "../../utils/Loader";

const ScoreCard = ({
  title,
  scoreMetadata,
  benchmark = 0,
  filters,
  totalDataPoints,
  negativeScoreEmployeeContributions,
  positiveScoreEmployeeContributions,
  neutralScoreEmployeeContributions,
  isLoading,
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showSentimentModal, setShowSentimentModal] = useState(false);
  const [selectedSentiment, setSelectedSentiment] = useState(null);
  const modalRef = useRef();

  benchmark = Math.round(benchmark);

  // Memoize the highest proportion results
  const highestProportionResults = useMemo(
    () => ({
      positive: positiveScoreEmployeeContributions
        ? getDepartmentWithHighestScoreProportion(
            positiveScoreEmployeeContributions
          )
        : null,
      negative: negativeScoreEmployeeContributions
        ? getDepartmentWithHighestScoreProportion(
            negativeScoreEmployeeContributions
          )
        : null,
      neutral: neutralScoreEmployeeContributions
        ? getDepartmentWithHighestScoreProportion(
            neutralScoreEmployeeContributions
          )
        : null,
    }),
    [
      positiveScoreEmployeeContributions,
      negativeScoreEmployeeContributions,
      neutralScoreEmployeeContributions,
    ]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowInfoModal(false);
        setShowSentimentModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getScoreColor = (score) => {
    if (score >= 75) return "bg-green-500";
    if (score >= 50) return "bg-yellow-500";
    return "bg-red-500";
  };

  const averageScore =
    scoreMetadata && scoreMetadata.length > 0
      ? Math.round(
          scoreMetadata
            .filter((item) => item.score_type !== "burnoutScore")
            .reduce((sum, item) => sum + item.score, 0) /
            scoreMetadata.filter((item) => item.score_type !== "burnoutScore")
              .length
        )
      : 0;
  const sentimentData = scoreMetadata
    ? scoreMetadata.find((item) => item.score_type === "sentimentScore")
    : null;
  const distribution = sentimentData ? sentimentData.distribution : {};
  const totalResponses = Object.values(distribution).reduce((a, b) => a + b, 0);

  const distributionPercentages = {
    positive:
      Math.round(
        (((distribution.positive || 0) + (distribution.very_positive || 0)) /
          totalResponses) *
          100
      ) || 0,
    neutral:
      Math.round(((distribution.neutral || 0) / totalResponses) * 100) || 0,
    negative:
      Math.round(
        (((distribution.negative || 0) + (distribution.very_negative || 0)) /
          totalResponses) *
          100
      ) || 0,
  };

  const openSentimentModal = (sentiment) => {
    setSelectedSentiment(sentiment);
    setShowSentimentModal(true);
  };

  const getTimeFrame = () => {
    return filters?.dateRange || "Last 30 Days";
  };

  const getTotalDataPoints = () => {
    if (!totalDataPoints || !totalDataPoints.totalDataPoints) return 0;
    return Object.values(totalDataPoints.totalDataPoints).reduce(
      (a, b) => a + b,
      0
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg shadow-md">
        <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full bg-white rounded-lg shadow-md p-6">
      <div className="flex-grow">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-bold text-gray-800">{title} Score</h3>
          <button
            className="bg-blue-100 hover:bg-blue-200 text-blue-800 font-bold py-2 px-4 rounded-full transition-colors duration-200 flex items-center"
            onClick={() => setShowInfoModal(true)}
          >
            <FaInfoCircle size={16} className="mr-2" />
            Details
          </button>
        </div>

        <div className="flex items-baseline mb-6">
          <span
            className={`text-6xl font-bold ${getScoreColor(
              averageScore
            ).replace("bg-", "text-")}`}
          >
            {averageScore}
          </span>
          <span className="text-2xl text-gray-500 ml-2">/ 100</span>
        </div>

        <div className="w-full bg-gray-200 rounded-full h-4 mb-4 relative">
    <div
      className={`h-4 rounded-full ${getScoreColor(averageScore)}`}
      style={{
        width: `${averageScore}%`,
        transition: "width 0.5s ease-out",
      }}
    ></div>
    {/* Add conditional rendering for the benchmark marker */}
    {benchmark > 0 && (
      <>
        <div
          className="absolute top-0 h-8 w-1 bg-blue-600 transform -translate-y-2"
          style={{ left: `${benchmark}%` }}
        ></div>
        <div
          className="absolute -top-10 bg-blue-100 text-blue-800 px-3 py-1 rounded-md text-sm font-semibold whitespace-nowrap"
          style={{ left: `${benchmark}%`, transform: "translateX(-50%)" }}
        >
          Worksense Average: {benchmark}
          <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-full">
            <div className="border-solid border-t-blue-100 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
          </div>
        </div>
      </>
    )}
  </div>

        <div className="grid grid-cols-3 gap-4 mb-6">
          <SentimentIcon
            Icon={IoMdHappy}
            percentage={distributionPercentages.positive}
            label="Positive"
            color="text-green-500"
            onClick={() => openSentimentModal("Positive")}
          />
          <SentimentIcon
            Icon={MdSentimentNeutral}
            percentage={distributionPercentages.neutral}
            label="Neutral"
            color="text-yellow-500"
            onClick={() => openSentimentModal("Neutral")}
          />
          <SentimentIcon
            Icon={IoMdSad}
            percentage={distributionPercentages.negative}
            label="Negative"
            color="text-red-500"
            onClick={() => openSentimentModal("Negative")}
          />
        </div>
      </div>

      <div className="mt-auto text-sm text-gray-500 flex justify-between items-center pt-4 border-t border-gray-200">
        <span>{getTimeFrame()}</span>
        <span>Total Data Points: {getTotalDataPoints()}</span>
      </div>

      {showInfoModal && (
        <Modal
          onClose={() => setShowInfoModal(false)}
          title="Score Details"
          ref={modalRef}
        >
          <div className="mb-6 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold text-blue-800 mb-2 flex items-center">
              <FaChartLine className="mr-2" />
              Score Calculation Methodology
            </h4>
            <p className="text-gray-700">
              Our score is calculated using data from all integrations and
              metadata, providing a holistic view of sentiment and performance
              within your organization. For detailed information on each metric,
              please refer to the{" "}
              <a
                href="/definition-glossary"
                className="text-blue-600 hover:underline"
              >
                Definition Glossary{" "}
                <FaExternalLinkAlt className="inline-block ml-1" size={12} />
              </a>
              .
            </p>
          </div>
          <ScoreTable items={scoreMetadata || []} />
        </Modal>
      )}

      {showSentimentModal && (
        <Modal
          onClose={() => setShowSentimentModal(false)}
          title={`${selectedSentiment} Sentiment Details`}
          ref={modalRef}
        >
          <SentimentDetails
            sentiment={selectedSentiment}
            positiveScoreEmployeeContributions={
              positiveScoreEmployeeContributions
            }
            negativeScoreEmployeeContributions={
              negativeScoreEmployeeContributions
            }
            neutralScoreEmployeeContributions={
              neutralScoreEmployeeContributions
            }
            highestProportionResults={highestProportionResults}
          />
        </Modal>
      )}
    </div>
  );
};

const SentimentIcon = ({ Icon, percentage, label, color, onClick }) => (
  <div
    className="flex flex-col items-center cursor-pointer transition-transform duration-200 hover:transform hover:scale-105"
    onClick={onClick}
  >
    <Icon className={`${color} text-4xl mb-2`} />
    <span className={`text-xl font-bold ${color}`}>{percentage}%</span>
    <span className="text-sm text-gray-600">{label}</span>
  </div>
);

const Modal = React.forwardRef(({ children, onClose, title }, ref) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div
      ref={ref}
      className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto"
    >
      <div className="flex justify-between items-center p-6 border-b border-gray-200">
        <h3 className="text-2xl font-semibold text-gray-800">{title}</h3>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
        >
          <FaTimes size={24} />
        </button>
      </div>
      <div className="p-6">{children}</div>
    </div>
  </div>
));

const SentimentDetails = ({ sentiment, highestProportionResults }) => {
  if (
    !highestProportionResults ||
    !highestProportionResults[sentiment.toLowerCase()]
  ) {
    return (
      <div className="bg-gray-50 p-6 rounded-lg space-y-6">
        <Loader
          loading={true}
          size={50}
          color={"#123abc"}
          loadingText={"Loading Dashboard"}
        ></Loader>
      </div>
    );
  }

  const data = highestProportionResults[sentiment.toLowerCase()];
  const { department, proportion, scoreProportions } = data;

  // Find the highest score proportion
  const highestScoreProportion = Object.entries(scoreProportions).reduce(
    (highest, [scoreType, value]) =>
      value > highest.value ? { scoreType, value } : highest,
    { scoreType: "", value: 0 }
  );

  const keyInsight = `${highestScoreProportion.scoreType.replace(
    /_/g,
    " "
  )} has the highest impact with ${(highestScoreProportion.value * 100).toFixed(
    2
  )}% in the ${department} department.`;

  const bgColor =
    sentiment === "Positive"
      ? "bg-green-50"
      : sentiment === "Neutral"
      ? "bg-yellow-50"
      : "bg-red-50";
  const textColor =
    sentiment === "Positive"
      ? "text-green-700"
      : sentiment === "Neutral"
      ? "text-yellow-700"
      : "text-red-700";

  return (
    <div className={`${bgColor} p-6 rounded-lg space-y-6`}>
      <div className="flex items-center space-x-4">
        <FaUsers className={`${textColor} text-3xl`} />
        <div>
          <h4 className={`${textColor} text-lg font-semibold`}>
            Most Influential Department
          </h4>
          <p className="text-2xl font-bold">{department}</p>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <FaBuilding className={`${textColor} text-3xl`} />
        <div>
          <h4 className={`${textColor} text-lg font-semibold`}>
            Overall Impact Score
          </h4>
          <p className="text-xl font-semibold">{proportion.toFixed(0)}%</p>
          <p className="text-lg">
            of employees in {department} have sent at least 10 {sentiment}{" "}
            messages
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;
