import {
  AiOutlineBarChart,
  AiOutlineCloudSync,
  AiOutlineLock,
  AiOutlineTeam,
} from "react-icons/ai";
import { motion } from "framer-motion";

const EnterpriseFeatures = () => {
  const features = [
    {
      icon: <AiOutlineTeam size="3em" />,
      title: "Advanced Team Analytics",
      description:
        "Gain deep insights into team dynamics, collaboration, and productivity using AI-powered analytics.",
    },
    {
      icon: <AiOutlineBarChart size="3em" />,
      title: "Flexible Reporting",
      description:
        "Customizable dashboards and reports tailored for different stakeholders and decision-makers.",
    },
    {
      icon: <AiOutlineLock size="3em" />,
      title: "Robust Security",
      description:
        "Enterprise-grade security with encryption at rest and in transit to protect your data.",
    },
    {
      icon: <AiOutlineCloudSync size="3em" />,
      title: "Seamless Integrations",
      description:
        "Easily connect with your existing toolchain, including Slack, Teams, Zoom, and more.",
    },
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-100 to-white">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-900">
          Powerful Features for Data-Driven Enterprises
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05 }}
              className="bg-white p-8 rounded-lg shadow-md text-center transition transform hover:shadow-xl hover:-translate-y-1"
            >
              <div className="flex items-center justify-center w-16 h-16 mb-6 mx-auto bg-blue-100 rounded-full">
                {feature.icon}
              </div>
              <h3 className="text-2xl font-bold mb-2 text-gray-800">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EnterpriseFeatures;
