import React, { useEffect, useState } from 'react';
import TitleCard from "../Cards/TitleCard";
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { getSelectedCompanyId } from '../../utils/companyStorage';
import { auth } from '../../firebase';
import { LuAlertTriangle } from "react-icons/lu";
import * as Constants from "../../utils/Constants";

const IntegrationAlert = ({ type }) => {
  return (
    <div className="inline-flex items-center px-4 py-2 rounded-md bg-amber-400 text-amber-900 mb-4 mr-4">
      <LuAlertTriangle size={20} className="mr-2" />
      <span className="font-bold">No active {type} integrations</span>
    </div>
  );
};

const GroupedIntegrations = ({ integrationList, isDemoUser, onToggle, renderInitialIntegration }) => {
  const [isHrisIntegrated, setIsHrisIntegrated] = useState(null);
  const [isCommunicationsIntegrated, setIsCommunicationsIntegrated] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkIntegrations = async () => {
      try {
        setError(null);
        const user = auth.currentUser;
        if (!user) {
          setError("No user is currently signed in.");
          return;
        }

        const companyId = await getSelectedCompanyId(user.uid);
        if (!companyId) {
          setError("No company ID found for the current user.");
          return;
        }

        const companyRef = doc(db, 'companies', companyId);
        const companySnap = await getDoc(companyRef);

        if (companySnap.exists()) {
          const companyData = companySnap.data();

          if (companyData.integrations) {
            const isAnyHrisActive = Constants.HRIS_INTEGRATIONS.some(hris =>
              companyData.integrations[hris] && companyData.integrations[hris].isActive
            );

            const isAnyCommunicationsActive = Constants.COMMUNICATION_INTEGRATIONS.some(comm =>
              companyData.integrations[comm] && companyData.integrations[comm].isActive
            );

            setIsHrisIntegrated(isAnyHrisActive);
            setIsCommunicationsIntegrated(isAnyCommunicationsActive);
          } else {
            setIsHrisIntegrated(false);
            setIsCommunicationsIntegrated(false);
          }
        } else {
          setError("No company data found.");
        }
      } catch (error) {
        setError("An error occurred while checking integrations.");
      }
    };

    checkIntegrations();
  }, []);

  const groupedIntegrations = integrationList.reduce((acc, integration) => {
    const type = integration.type || 'Other';
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(integration);
    return acc;
  }, {});

  const sortOrder = ['HRIS', 'communication'];

  const sortedTypes = Object.keys(groupedIntegrations).sort((a, b) => {
    const indexA = sortOrder.indexOf(a);
    const indexB = sortOrder.indexOf(b);
    
    if (indexA !== -1 && indexB !== -1) return indexA - indexB;
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;
    return a.localeCompare(b);
  });

  const sortIntegrations = (a, b) => {
    const orderDiff = (a.order || Infinity) - (b.order || Infinity);
    if (orderDiff !== 0) return orderDiff;
    return a.name.localeCompare(b.name);
  };

  Object.keys(groupedIntegrations).forEach(type => {
    groupedIntegrations[type].sort(sortIntegrations);
  });

  return (
    <div className="space-y-8">
    {error && (
      <div className="alert alert-error shadow-lg mb-6">
        <div>
          <LuAlertTriangle size={20} className="mr-2" />
          <span>Error: {error}</span>
        </div>
      </div>
    )}
    <div className="flex flex-wrap">
      {isHrisIntegrated === false && <IntegrationAlert type="HRIS" />}
      {isCommunicationsIntegrated === false && <IntegrationAlert type="Communications" />}
    </div>
      {sortedTypes.map((type) => (
        <div key={type}>
          <h2 className="text-2xl font-bold mb-4 capitalize">{type}</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {groupedIntegrations[type].map((integration) => (
              <TitleCard key={integration.id} title={integration.name} topMargin="mt-2">
                <div className="flex flex-col h-full justify-between">
                  <div>
                    <div className="flex items-start mb-4">
                      <img
                        alt={`${integration.name} icon`}
                        src={integration.icon}
                        className="w-12 h-12 mr-4"
                      />
                      <p className="flex-grow">{integration.description}</p>
                    </div>
                  </div>
                  <div className="mt-auto">
                    {renderInitialIntegration(integration)}
                    {integration.initialized && (
                      <div className="flex justify-end items-center mt-4">
                        <span className={`mr-2 font-semibold ${integration.isActive ? 'text-green-500' : 'text-gray-500'}`}>
                          {integration.isActive ? 'Active' : 'Inactive'}
                        </span>
                        <Toggle
                          checked={integration.isActive}
                          onChange={() => onToggle(integration.id, integration.isActive)}
                          icons={false}
                          className="react-toggle-custom"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </TitleCard>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroupedIntegrations;