import { setDoc, doc } from "firebase/firestore";

import * as Constants from "../utils/Constants";
import { db, auth } from "../firebase";

export const saveBambooHrData = async (subdomain, apiKey) => {
  if (auth.currentUser) {
    const userDocRef = doc(
      db,
      Constants.COMPANY_TABLE_NAME,
      auth.currentUser.uid
    );

    const bambooData = {
      integrations: {
        [Constants.BAMBOO]: {
          initialized: true,
          isActive: true,
          apiKey: apiKey,
          subdomain: subdomain,
        },
      },
    };

    await setDoc(userDocRef, bambooData, { merge: true });
  }
};

export const saveHiBobData = async (serviceId, token) => {
  if (auth.currentUser) {
    const userDocRef = doc(
      db,
      Constants.COMPANY_TABLE_NAME,
      auth.currentUser.uid
    );

    const hiBobData = {
      integrations: {
        [Constants.HIBOB]: {
          initialized: true,
          isActive: true,
          serviceId: serviceId,
          token: token,
        },
      },
    };

    await setDoc(userDocRef, hiBobData, { merge: true });
  }
};

export const saveMattermostData = async (subdomain, user, pass) => {
  if (auth.currentUser) {
    const userDocRef = doc(
      db,
      Constants.COMPANY_TABLE_NAME,
      auth.currentUser.uid
    );

    const mattermostData = {
      integrations: {
        [Constants.MATTERMOST]: {
          initialized: true,
          isActive: true,
          subdomain: subdomain,
          user: user,
          pass: pass,
        },
      },
    };
    await setDoc(userDocRef, mattermostData, { merge: true });
  }
};

export const saveTeamsData = async (teamsIds) => {
  if (auth.currentUser) {
    const userDocRef = doc(
      db,
      Constants.COMPANY_TABLE_NAME,
      auth.currentUser.uid
    );
    var teamsIdsArr = teamsIds.split(",");
    const teamsData = {
      integrations: {
        [Constants.TEAMS]: {
          teamsIds: teamsIdsArr,
        },
      },
    };
    await setDoc(userDocRef, teamsData, { merge: true });
  }
};
