import React from "react";
import { motion } from "framer-motion";
import { itemVariants } from "./AnimationsVariants";

const CompanySize = ({ numberOfEmployees, setNumberOfEmployees, errors }) => {
  return (
    <>
      <motion.h2
        className="text-2xl font-semibold mb-8 text-center text-blue-800"
        variants={itemVariants}
      >
        Provide The Size Of Your Company
      </motion.h2>
      <motion.p
        className="text-lg mb-6 text-center text-gray-600"
        variants={itemVariants}
      >
        We need to know the size of your company to recommend the best subscription plan and estimate your data needs. This information will also help us tailor your profile setup.
      </motion.p>
      <motion.div className="mb-6" variants={itemVariants}>
        <label className="block mb-2 font-semibold text-blue-800 text-lg">
          Select the number of employees
        </label>
        <select
          className={`w-full px-4 py-3 border rounded-lg text-lg ${
            errors.numberOfEmployees
              ? 'border-red-500 focus:border-red-500 border-2 focus:ring-0'
              : 'border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500'
          }`}
          value={numberOfEmployees}
          onChange={(e) => setNumberOfEmployees(e.target.value)}
        >
          <option value="">Select an option</option>
          <option value="1-50">1-50</option>
          <option value="51-100">51-100</option>
          <option value="101-200">101-200</option>
          <option value="201-300">201-300</option>
          <option value="301-400">301-400</option>
          <option value="401-500">401-500</option>
          <option value="501-600">501-600</option>
          <option value="601-700">601-700</option>
          <option value="701-800">701-800</option>
          <option value="801-900">801-900</option>
          <option value="901-1000">901-1000</option>
          <option value="1001+">1001+</option>
        </select>
        {errors.numberOfEmployees && (
          <p className="text-red-500 text-sm mt-2">
            {errors.numberOfEmployees}
          </p>
        )}
      </motion.div>
    </>
  );
};

export default CompanySize;
