import React from "react";
import { motion } from "framer-motion";
import { AiOutlineTeam, AiOutlineBulb, AiOutlineRocket } from "react-icons/ai";

const AboutUs = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="bg-gradient-to-b from-cyan-500 to-blue-500 min-h-screen text-white">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <motion.h1
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          className="text-5xl font-extrabold mb-8 text-center"
        >
          About WorkSense
        </motion.h1>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          className="bg-white text-blue-900 p-8 rounded-lg shadow-xl mb-12"
        >
          <h2 className="text-3xl font-bold mb-4">Our Story</h2>
          <p className="text-lg mb-4">
            Worksense was founded with a vision to revolutionize workplace
            dynamics through AI-powered sentiment analysis and actionable
            insights. We believe that understanding and improving employee
            sentiment is key to creating thriving, productive work environments.
          </p>
          <p className="text-lg">
            Our cutting-edge technology eliminates the need for traditional
            surveys by automatically capturing real-time sentiment data. This
            allows companies to make data-driven decisions that enhance employee
            satisfaction, boost retention rates, and ultimately drive business
            success.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
          {[
            {
              icon: <AiOutlineTeam size="3em" />,
              title: "Our Mission",
              description:
                "To empower organizations with real-time insights that foster positive workplace cultures and drive employee success.",
            },
            {
              icon: <AiOutlineBulb size="3em" />,
              title: "Our Vision",
              description:
                "A world where every workplace is optimized for employee happiness, productivity, and growth through data-driven decision making.",
            },
            {
              icon: <AiOutlineRocket size="3em" />,
              title: "Our Approach",
              description:
                "Leveraging cutting-edge AI and data analytics to provide actionable insights that transform workplace dynamics.",
            },
          ].map((item, index) => (
            <motion.div
              key={index}
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ delay: index * 0.2 }}
              className="bg-white text-blue-900 p-6 rounded-lg shadow-xl"
            >
              <div className="flex items-center justify-center mb-4">
                {item.icon}
              </div>
              <h2 className="text-2xl font-bold mb-2 text-center">
                {item.title}
              </h2>
              <p className="text-center">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;