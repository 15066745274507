import alchemistLogo from "../../images/logos/alchemistlogo.png";
import antlerLogo from "../../images/logos/antlerLogo.png";

const BackedBySection = () => (
  <section className="py-16 bg-white">
    <div className="container mx-auto px-4">
      <div className="text-center mb-12">
        <h2 className="text-4xl md:text-5xl font-bold text-gray-800 mb-8">BACKED BY</h2>
        <div className="flex flex-wrap justify-center items-center gap-8">
          <img src={alchemistLogo} alt="Alchemist Logo" className="h-24 object-contain transform transition duration-300 hover:scale-105" />
          <img src={antlerLogo} alt="Antler Logo" className="h-24 object-contain transform transition duration-300 hover:scale-105" />
        </div>
      </div>
    </div>
  </section>
);

export default BackedBySection;
