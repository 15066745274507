import React from 'react';
import { ExclamationTriangleIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

const HRISIntegrationAlert = ({ totalDataPoints }) => {
  const needsIntegration = totalDataPoints.hr_metadata_total === 0 && totalDataPoints.employees_total === 0;

  if (!needsIntegration) return null;

  return (
    <div className="alert bg-amber-400/90 shadow-lg border border-amber-500/50">
      <div className="flex flex-col w-full">
        <div className="flex items-start mb-4">
          <ExclamationTriangleIcon className="h-6 w-6 flex-shrink-0 mr-3 text-amber-900" />
          <div>
            <h3 className="font-bold text-amber-900">HRIS Integration Required</h3>
            <div className="text-sm mt-1 text-amber-900/90">
              Your dashboard is missing crucial HRIS data. Integrate your HRIS system to unlock full functionality and comprehensive insights.
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-3">
          <button 
            className="btn btn-sm bg-transparent border-amber-600 text-amber-900 hover:bg-amber-500/20"
            onClick={() => window.location.href = 'mailto:noreply@worksenseai.com'}
          >
            Contact Support
          </button>
          <button 
            className="btn btn-sm bg-amber-600 border-amber-600 text-white hover:bg-amber-700 hover:border-amber-700"
            onClick={() => window.location.href = '/integrations'}
          >
            Integrate Now
            <ArrowRightIcon className="ml-2 h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HRISIntegrationAlert;