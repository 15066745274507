import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

export function useAdminStatus() {
  const [isAdminUser, setIsAdminUser] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAdminUser(user.uid === "4KISCpZsWBd2xinV7d4M1P9kZFq2");
      } else {
        setIsAdminUser(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return isAdminUser;
}
