import { motion } from "framer-motion";

const CTASection = () => (
  <section className="bg-gradient-to-r from-cyan-500 to-blue-500 py-20 text-center text-white">
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-4xl font-bold mb-8">
        Ready to Revolutionize Your Workforce Analytics?
      </h2>
      <p className="text-xl mb-8">
        Take the first step towards data-driven decision-making with Worksense AI.
      </p>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="bg-white text-blue-900 font-bold py-4 px-8 rounded-full text-2xl shadow-lg"
        href="https://cal.com/arshanahmad/30min"
      >
        <a href="https://cal.com/arshanahmad/30min">Request a Demo</a>
      </motion.button>
    </div>
  </section>
);

export default CTASection;
