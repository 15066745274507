// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
export const firebaseApp = initializeApp({
  apiKey: "AIzaSyD1GI82Ka2ZFowlfSZfECQC3XRHGVhrLLY",
  authDomain: "worksense-c6b70.firebaseapp.com",
  projectId: "worksense-c6b70",
  storageBucket: "worksense-c6b70.appspot.com",
  messagingSenderId: "146068277190",
  appId: "1:146068277190:web:ab38c2e4368f9f974154c7",
  measurementId: "G-HRHCM0DF49",
});

const analytics = getAnalytics(firebaseApp);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);