// Table constants
export const COMPANY_TABLE_NAME = "companies";
export const EMPLOYEE_TABLE_NAME = "employees";
export const AGGREGATION_TABLE_NAME = "aggregations";
export const MESSAGES_TABLE_NAME = "messages";
export const HR_METADATA_TABLE_NAME = "hrMetadata";
export const METADATA_DOCUMENT_NAME = "metadata";
export const FILTER_TABLE_NAME = "filters";
export const OPTION_DOCUMENT_NAME = "options";

// Integration constants
export const SLACK = "slack";
export const BAMBOO = "bambooHr";
export const JIRA = "jira";
export const CONFLUENCE = "confluence";
export const TEAMS = "teams";
export const GOOGLE_MEET = "googleMeet";
export const GMAIL = "gmail";
export const RIPPLING = "rippling";
export const WORKDAY = "workday";
export const ZOOM = "zoom";
export const MATTERMOST = "mattermost";
export const OUTLOOK = "outlook";
export const DRIVE = "drive";
export const GITHUB = "github";
export const CSV = "CSV";
export const HIBOB = "hibob";
export const FILE_STORAGE = "file storage"
export const HRIS = "HRIS"
export const COMMUNICATION = "communication"
export const MAIL = "mail"
export const TICKETING = "ticketing"
export const DOCUMENTATION = "documentation"
export const LIVE_INTEGRATIONS = [SLACK, BAMBOO, TEAMS, MATTERMOST, CSV, HIBOB];
export const HRIS_INTEGRATIONS = [BAMBOO, HIBOB, RIPPLING, WORKDAY];
export const COMMUNICATION_INTEGRATIONS = [SLACK, TEAMS, ZOOM];



// Score types
export const SENTIMENT_SCORE_TYPE = "Sentiment";
export const ENERGY_SCORE_TYPE = "Energy";
export const FOCUS_SCORE_TYPE = "Focus";
export const MOTIVATION_SCORE_TYPE = "Motivation";
export const ENGAGEMENT_SCORE_TYPE = "Engagement";
export const POSITIVITY_SCORE_TYPE = "Positivity";

export const INTEGRATION_ICONS = {
  [SLACK]: "https://cdn-icons-png.flaticon.com/512/2111/2111615.png",
  [TEAMS]: "https://cdn-icons-png.flaticon.com/512/906/906349.png",
  [BAMBOO]:
    "https://leadsbridge.com/wp-content/themes/leadsbridge/img/integration-lg-logos/logo456.png",
  [ZOOM]: "https://cdn-icons-png.flaticon.com/512/4401/4401470.png",
  [CONFLUENCE]: "https://cdn-icons-png.flaticon.com/512/5968/5968793.png",
  [GOOGLE_MEET]: "https://cdn-icons-png.flaticon.com/512/5968/5968552.png",
  [GMAIL]: "https://cdn-icons-png.flaticon.com/512/5968/5968534.png",
  [RIPPLING]:
    "https://play-lh.googleusercontent.com/JmTADHoAPMDtXtseUpGpBTxHzeS8og8gxFKFjsz2xdjhMR9P-nC2cnabkOTWCjcSEyDU",
  [WORKDAY]:
    "https://seeklogo.com/images/W/workday-logo-4971E992CF-seeklogo.com.png",
  [JIRA]: "https://cdn-icons-png.flaticon.com/512/5968/5968875.png",
  [GITHUB]: "https://cdn-icons-png.flaticon.com/512/2111/2111432.png",
  [MATTERMOST]: "https://cdn-icons-png.flaticon.com/512/906/906391.png",
  [OUTLOOK]: "https://cdn-icons-png.flaticon.com/512/732/732223.png",
  [DRIVE]: "https://cdn-icons-png.flaticon.com/512/5968/5968523.png",
  [HIBOB]: "https://media.tekpon.com/2024/03/HiBob-Logo.webp",
};

export const FORMAT_NUMBER = (num) => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");