// src/components/AnomalyDetection.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { motion } from 'framer-motion';

const AnomalyDetection = () => {
  // Mock data for chart
  const chartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Normal Range',
        data: [65, 59, 80, 81, 56, 55],
        fill: true,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.4,
      },
      {
        label: 'Anomalies',
        data: [null, 59, null, 81, null, 45], // Simulated anomaly data points
        fill: false,
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 4,
        pointRadius: 6,
        pointBackgroundColor: 'rgba(255, 99, 132, 1)',
        tension: 0.4,
      }
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 20,
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // Mock data for stats tiles with gradient backgrounds
  const statsData = [
    {
      title: 'Time Management',
      value: '2.9 / 10',
      description: 'Unsustainable',
      gradientFrom: 'from-red-500',
      gradientTo: 'to-red-300',
    },
    {
      title: 'Burnout Risk',
      value: '4.5 / 10',
      description: 'Poor',
      gradientFrom: 'from-yellow-500',
      gradientTo: 'to-yellow-300',
    },
    // ...other data
  ];

  // Animation variants for Framer Motion
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.8 }}
      className="bg-gradient-to-tr p-8 rounded-3xl shadow-xl"
    >
      <h2 className="text-3xl font-bold text-gray-800 mb-8">Anomaly Detection</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-10">
        {statsData.map((item, index) => (
          <motion.div
            key={index}
            className={`rounded-lg p-6 shadow-lg bg-gradient-to-br ${item.gradientFrom} ${item.gradientTo}`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <h3 className="text-xl font-semibold text-white">{item.title}</h3>
            <p className="text-4xl font-bold text-white my-2">{item.value}</p>
            <p className="text-md text-gray-200">{item.description}</p>
          </motion.div>
        ))}
      </div>
      <div className="relative h-96">
        <Line data={chartData} options={chartOptions} />
      </div>
    </motion.div>
  );
};

export default AnomalyDetection;
