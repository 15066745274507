import React from "react";
import Loader from "../../utils/Loader";
import ScoreCard from "./ScoreCard";
import MinimalLineChart from "./MinimalLineChart";
import Recommendations from "../../routes/Recommendations";
import BarData from "../BarData";

export default function Reports({
  data,
  totalDataPoints,
  filters,
  isDemoUser = false,
  messageScoreAveragesData,
  overallScoresAndBenchmark,
  timeData,
  teams,
  negativeScoreEmployeeContributions,
  positiveScoreEmployeeContributions,
  neutralScoreEmployeeContributions,
  handleTeamChange,
}) {
  filters = filters || {};

  const findScoreAndDistribution = (scoreType) => {
    if (!messageScoreAveragesData) return { score: "N/A", distribution: {} };

    const scoreData = messageScoreAveragesData.find(
      (s) => s.score_type === scoreType
    );
    if (!scoreData) return { score: "N/A", distribution: {} };
    return {
      score: Math.floor(scoreData.score),
      distribution: scoreData.distribution,
    };
  };

  const sentimentData = messageScoreAveragesData
    ? findScoreAndDistribution("sentimentScore")
    : { score: "", distribution: {} };
  const burnoutData = messageScoreAveragesData
    ? findScoreAndDistribution("burnoutScore")
    : { score: "", distribution: {} };

  const isLoading = !data;

  return (
    <div className="p-6 bg-white">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">
        Company Snapshot
      </h1>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <h2 className="text-xl font-semibold p-4 bg-gray-50 border-b">
            Sentiment Analysis
          </h2>
          <div className="p-4">
            {isLoading ? (
              <Loader size={50} color={"#123abc"} />
            ) : (
              <ScoreCard
                title="Sentiment"
                score={sentimentData.score}
                distribution={sentimentData.distribution}
                scoreMetadata={messageScoreAveragesData}
                benchmark={overallScoresAndBenchmark?.worksense_benchmark || 0}
                negativeScoreEmployeeContributions={
                  negativeScoreEmployeeContributions
                }
                positiveScoreEmployeeContributions={
                  positiveScoreEmployeeContributions
                }
                neutralScoreEmployeeContributions={
                  neutralScoreEmployeeContributions
                }
                filters={filters}
                totalDataPoints={totalDataPoints}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <h2 className="text-xl font-semibold p-4 bg-gray-50 border-b">
            Sentiment Trend
          </h2>
          <div className="p-4">
            {isLoading ? (
              <Loader size={50} color={"#123abc"} />
            ) : (
              <MinimalLineChart
                title="Department Sentiment Comparison - Day Over Day"
                dataSet={timeData.scoreTrendDayOverDayData || []}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>

      {/* <div className="mt-12 bg-white rounded-lg shadow-md overflow-hidden">
        <h2 className="text-xl font-semibold p-4 bg-gray-50 border-b">
          Key Insights
        </h2>
        <div className="p-4">
          {isLoading ? (
            <Loader size={50} color={"#123abc"} />
          ) : (
            <BarData
              title={`Top ${filters.scoreType} Scores`}
              dataSet={scoreByTopData || []}
              color="#4a90e2"
            />
          )}
        </div>
      </div> */}
    </div>
  );
}
