import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../firebase";
import { getDoc, doc } from "firebase/firestore";
import * as Constants from "../utils/Constants";

export const checkAuth = (setUser, setUserToken, setIsDemoUser) => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const token = await firebaseUser.getIdToken();
          setUser(firebaseUser);
          setUserToken(token);
          window.userUid = firebaseUser.uid;

          await fetchUser(firebaseUser, setIsDemoUser);
          resolve();
        } catch (err) {
          reject(err);
        }
      } else {
        window.location.href = "/signin";
      }
    });
  });
};

function fetchUser(firebaseUser, setIsDemoUser) {
  return new Promise((resolve, reject) => {
    const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, firebaseUser.uid);
    getDoc(userDocRef)
      .then((userDocSnapshot) => {
        const userData = userDocSnapshot.data();

        if (userData && typeof userData.isDemoUser !== "undefined") {
          setIsDemoUser(userData.isDemoUser);
        }
        resolve(); // Resolve the promise once the user data is handled
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        reject(error); // Reject the promise if there's an error
      });
  });
}

export const checkAuthWithWorker = (
  setUserUid,
  setUserEmail,
  setUserDisplayName,
  workerFunction,
  initFunction
) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserUid(user.uid);
      setUserDisplayName(user.displayName);
      setUserEmail(user.email);

      if (initFunction) {
        initFunction(user);
      }

      workerFunction(user.uid);

      window.userUid = user.uid;
      //return [user.uid, user.displayName, user.email];
    } else {
      window.location.href = "/signin";
    }
  });
};
