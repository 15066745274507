import logo from "../../images/logos/worksenselogo.png";

const Footer = () => (
  <footer className="bg-gray-900 text-white py-12">
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
          <img src={logo} alt="Worksense AI Logo" className="h-12 mb-4" />
          <p className="text-gray-400">
            Revolutionizing workforce analytics with AI-powered insights.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-bold mb-4">Product</h3>
          <ul className="space-y-2">
            <li>
              <a href="/product" className="text-gray-400 hover:text-white">
                Features
              </a>
            </li>
            <li>
              <a href="/integrations" className="text-gray-400 hover:text-white">
                Integrations
              </a>
            </li>
            <li>
              <a href="https://cal.com/arshanahmad/30min" className="text-gray-400 hover:text-white">
                Pricing
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-bold mb-4">Company</h3>
          <ul className="space-y-2">
            <li>
              <a href="/about-us" className="text-gray-400 hover:text-white">
                About Us
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/worksense-ai/" className="text-gray-400 hover:text-white">
                Linkedin
              </a>
            </li>
            <li>
              <a href="https://cal.com/arshanahmad/30min" className="text-gray-400 hover:text-white">
                Contact Us
              </a>
            </li>
            <li>
              <a href="https://cal.com/arshanahmad/30min" className="text-gray-400 hover:text-white">
                Careers
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-bold mb-4">Resources</h3>
          <ul className="space-y-2">
            <li>
              <a href="/privacy-policy" className="text-gray-400 hover:text-white">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/terms-of-service" className="text-gray-400 hover:text-white">
                Terms of Service
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="my-8 border-gray-700" />
      <p className="text-center text-gray-400">
        &copy; {new Date().getFullYear()} Worksense Analytics. All rights reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
