import React, { useState } from "react";
import CSVButton from "./CSVbutton";
import { FaChevronDown, FaChevronUp, FaExternalLinkAlt, FaInfoCircle } from "react-icons/fa";

const CSVIntegrationContent = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const templateUrl = "https://docs.google.com/spreadsheets/d/16zneraJFEu78xVK8_0tEN6H2-GXaE3IRdautaWFx6yg/edit?usp=sharing";

  return (
    <div className="space-y-4">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex justify-between items-center bg-gray-100 hover:bg-gray-200 transition-colors duration-200 p-4 rounded-md font-medium text-gray-700"
        aria-expanded={isExpanded}
      >
        <span className="flex items-center">
          <FaInfoCircle className="mr-2" />
          CSV Upload Instructions
        </span>
        {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
      </button>

      {isExpanded && (
        <div className="bg-white border border-gray-200 p-4 rounded-md shadow-sm text-sm">
          <ol className="list-decimal list-inside space-y-2">
            <li>Open our CSV template in Google Sheets (link below).</li>
            <li>Fill in your employee data. <strong>Required fields</strong> are:
              <ul className="list-disc list-inside ml-4 mt-1">
                <li>Employee Email</li>
                <li>Department</li>
                <li>Start Date</li>
              </ul>
            </li>
            <li>We highly recommend filling in as many additional fields as possible for a more comprehensive dataset.</li>
            <li>Use the upload form below to submit your CSV file.</li>
          </ol>
          <div className="mt-4 text-gray-600">
            <strong>Note:</strong> Providing more data enables better insights and analytics in our platform.
          </div>
          <div className="mt-4 text-gray-600">
            <strong>Need help?</strong> Contact <a href="mailto:arshan@worksenseai.com" className="text-blue-600 hover:underline">arshan@worksenseai.com</a> for assistance with CSV uploads or data formatting.
          </div>
        </div>
      )}

      <div className="mt-4">
        <a
          href={templateUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200"
        >
          <FaExternalLinkAlt className="mr-2" />
          Open CSV Template in Google Sheets
        </a>
      </div>

      <div className="mt-6">
        <h5 className="font-medium mb-2">Upload CSV</h5>
        <CSVButton />
      </div>
    </div>
  );
};

export default CSVIntegrationContent;