import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from "../containers/Layout";
import Loader from "../utils/Loader";
import { checkAuth } from "../utils/AuthCheck";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import * as Constants from "../utils/Constants";
import { getSelectedCompanyId } from "../utils/companyStorage";
import validatePayment from "../utils/ValidatePayment";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const OrgChartPage = () => {
  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isDemoUser, setIsDemoUser] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [parentNodes, setParentNodes] = useState([]);

  const orgData = {
    id: 'CEO',
    name: 'John Doe',
    title: 'CEO',
    children: [
      {
        id: 'CTO',
        name: 'Jane Smith',
        title: 'CTO',
        children: [
          { id: 'DEV1', name: 'Bob Johnson', title: 'Lead Developer' },
          { id: 'DEV2', name: 'Alice Williams', title: 'UX Designer' }
        ]
      },
      {
        id: 'CFO',
        name: 'Mike Brown',
        title: 'CFO',
        children: [
          { id: 'FIN1', name: 'Sarah Davis', title: 'Financial Analyst' }
        ]
      },
      { id: 'HR', name: 'Emily Wilson', title: 'HR Director' }
    ]
  };

  useEffect(() => {
    async function authenticateUser() {
      try {
        await checkAuth(setUser, setUserToken, setIsDemoUser);
      } catch (error) {
        console.error("Error during authentication:", error);
        setError("Authentication failed. Please try again.");
        setIsLoading(false);
      }
    }

    authenticateUser();
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      if (user && user.uid) {
        try {
          const companyId = await getSelectedCompanyId(user.uid);

          const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, companyId);
          const userDocSnapshot = await getDoc(userDocRef);
          const userData = userDocSnapshot.data();
          setIsDemoUser(userData?.isDemoUser ?? true);

          const hasPaid = await validatePayment(user.uid);
          if (!hasPaid) {
            window.location.href = "/onboarding";
            return;
          }

          setCurrentNode(orgData);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setError("Failed to fetch user data. Please try again.");
        } finally {
          setIsLoading(false);
        }
      }
    }

    if (user) {
      fetchUserData();
    }
  }, [user]);

  const handleNodeClick = (node) => {
    if (node.children && node.children.length > 0) {
      setParentNodes([...parentNodes, currentNode]);
      setCurrentNode(node);
    }
  };

  const handleParentClick = () => {
    if (parentNodes.length > 0) {
      const newParents = [...parentNodes];
      const lastParent = newParents.pop();
      setParentNodes(newParents);
      setCurrentNode(lastParent);
    }
  };

  const renderNode = (node, isCurrentNode = false) => {
    if (!node) return null;
    return (
      <div 
        className={`
          bg-white border border-gray-300 rounded-lg p-4 pb-8
          transition-all duration-300 ease-in-out
          ${!isCurrentNode && node.children && node.children.length > 0 ? 'cursor-pointer hover:bg-gray-50' : ''}
          w-48 text-center relative
        `}
        onClick={() => !isCurrentNode && handleNodeClick(node)}
      >
        <div className="avatar placeholder mb-3">
          <div className="bg-blue-100 text-blue-600 rounded-full w-16 h-16 flex items-center justify-center">
            <span className="text-xl font-semibold">{node.name.split(' ').map(n => n[0]).join('')}</span>
          </div>
        </div>
        <div className="font-semibold text-gray-800 mb-1">{node.name}</div>
        <div className="text-sm text-gray-600">{node.title}</div>
        {!isCurrentNode && node.children && node.children.length > 0 && (
          <BsChevronDown className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-gray-400" />
        )}
      </div>
    );
  };

  const renderOrgChart = () => {
    if (!currentNode) return null;

    const childCount = currentNode.children ? currentNode.children.length : 0;
    const lineWidth = childCount * 192 + (childCount - 1) * 32 + 40; // 192px for each child node, 32px for gaps, and 40px extra

    return (
      <div className="flex flex-col items-center">
        {parentNodes.length > 0 && (
          <div className="mb-4 cursor-pointer" onClick={handleParentClick}>
            <BsChevronUp className="text-gray-400 text-2xl" />
          </div>
        )}
        <div className="mb-8">
          {renderNode(currentNode, true)}
        </div>
        {currentNode.children && currentNode.children.length > 0 && (
          <div className="relative w-full">
            <div className="absolute top-0 left-1/2 w-px h-8 bg-gray-300 transform -translate-x-1/2"></div>
            <div className="relative">
              <div 
                className="absolute top-8 left-1/2 h-px bg-gray-300 transform -translate-x-1/2"
                style={{ width: `${lineWidth}px` }}
              ></div>
              <div className="pt-16 flex justify-center">
                {currentNode.children.map((child, index) => (
                  <div key={child.id} className="relative px-4">
                    <div className="absolute top-0 left-1/2 w-px h-8 bg-gray-300 transform -translate-x-1/2"></div>
                    {renderNode(child)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
      <Loader
        loading={isLoading}
        size={50}
        color={"#123abc"}
        loadingText={"Loading organization chart"}
      >
        {error ? (
          <div className="text-center text-error">{error}</div>
        ) : (
          <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold text-gray-800 mb-8">Organization Chart</h1>
            <div className="bg-gray-100 rounded-lg shadow-lg p-6 overflow-auto" style={{ height: 'calc(100vh - 200px)' }}>
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentNode ? currentNode.id : 'empty'}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {renderOrgChart()}
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        )}
      </Loader>
    </Layout>
  );
};

export default OrgChartPage;