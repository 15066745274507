export const getFilterDatesConditions = (startDate, endDate) => {
  // Have to convert Datepicker date to regular date for both start and end date
  if (startDate && startDate.$d) {
    startDate = startDate.$d;
  }

  if (endDate && endDate.$d) {
    endDate = endDate.$d;
  }

  if (!endDate && !startDate) {
    return ["", ""];
  } else if (startDate && !endDate) {
    var queryParam = "&start_date=" + getDifferenceInDays(startDate);
    return [queryParam, ""];
  } else if (!startDate && endDate) {
    var queryParam = "&end_date=" + getDifferenceInDays(endDate);
    return ["", queryParam];
  } else {
    var startQueryParam = "&start_date=" + getDifferenceInDays(startDate);
    var endQueryParam = "&end_date=" + getDifferenceInDays(endDate);
    return [startQueryParam, endQueryParam];
  }
};

function getDifferenceInDays(date) {
  var today = new Date();
  let differenceInTime = today.getTime() - date.getTime();
  let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
}
