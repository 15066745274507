import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const generateCircles = () => {
  return Array.from({ length: 5 }, (_, index) => ({
    id: index,
    cx: Math.random() * 800,
    cy: Math.random() * 600,
    r: 20 + Math.random() * 30,
  }));
};

const circles = generateCircles();

export const AnimatedBackground = React.memo(() => {
  return (
    <div className="absolute top-0 left-0 w-full h-full z-0 overflow-hidden">
      <svg
        viewBox="0 0 800 600"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: "100%", height: "100%" }}
      >
        {circles.map((circle) => (
          <circle
            key={circle.id}
            cx={circle.cx}
            cy={circle.cy}
            r={circle.r}
            fill={`rgba(23, 107, 135, ${0.1 + circle.id * 0.1})`}
          >
            <animate
              attributeName="cx"
              values={`${circle.cx};${800 - circle.cx};${circle.cx}`}
              dur="20s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="cy"
              values={`${circle.cy};${600 - circle.cy};${circle.cy}`}
              dur="20s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="r"
              values={`${circle.r};${circle.r / 2};${circle.r}`}
              dur="20s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="opacity"
              values="0.1;0.5;0.1"
              dur="20s"
              repeatCount="indefinite"
            />
          </circle>
        ))}
      </svg>
    </div>
  );
});

export const SparkleEffect = () => {
    const [sparkles, setSparkles] = useState([]);
  
    const addSparkle = (event) => {
      const sparkle = {
        id: Date.now(),
        x: event.clientX,
        y: event.clientY,
        size: 20 + Math.random() * 40,
        duration: 0.6 + Math.random() * 0.4,
      };
      setSparkles([...sparkles, sparkle]);
      setTimeout(() => {
        setSparkles(sparkles.filter((s) => s.id !== sparkle.id));
      }, sparkle.duration * 1000);
    };
  
    useEffect(() => {
      document.addEventListener("click", addSparkle);
      return () => {
        document.removeEventListener("click", addSparkle);
      };
    }, []);
  
    return (
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
        {sparkles.map((sparkle) => (
          <motion.div
            key={sparkle.id}
            className="absolute bg-white rounded-full shadow-lg"
            initial={{ opacity: 1, scale: 0 }}
            animate={{
              opacity: 0,
              scale: 2,
              transition: {
                duration: sparkle.duration,
                ease: "easeOut",
              },
            }}
            style={{
              left: sparkle.x,
              top: sparkle.y,
              width: sparkle.size,
              height: sparkle.size,
            }}
          />
        ))}
      </div>
    );
  };