import React from "react";
import { motion } from "framer-motion";
import {
  FaChartLine,
  FaExclamationTriangle,
  FaLightbulb,
  FaPiggyBank,
} from "react-icons/fa";
import productImage1 from "../../images/productImage1.png";
import productImage2 from "../../images/productImage2.png";
import productImage3 from "../../images/productImage3.png";
import productImage4 from "../../images/productImage4.png";

const ProductFeatures = () => {
  const features = [
    {
      icon: <FaChartLine size={48} className="text-blue-600" />,
      title: "Sentiment Analysis",
      description:
        "Gain deep insights into the overall sentiment across your organization with Worksense's comprehensive reports. Our advanced AI algorithms analyze data from multiple sources, providing you with a holistic view of employee sentiment based on key dimensions and metrics.",
      points: [
        "Track sentiment trends over time to identify patterns and changes",
        "Pinpoint top-performing and bottom-performing teams for targeted interventions",
        "Benchmark your organization's performance against industry standards",
      ],
      image: productImage1,
      color: "#1D4ED8",
    },
    {
      icon: <FaExclamationTriangle size={48} className="text-yellow-500" />,
      title: "Problem Identification",
      description:
        "Stay ahead of potential issues by leveraging Worksense's advanced problem identification capabilities. Our platform analyzes key metrics across departments, providing a visual representation of areas that may require attention and intervention.",
      points: [
        "Identify potential trouble spots before they escalate into major issues",
        "Prioritize areas of concern based on data-driven insights",
        "Allocate resources effectively to address identified problem areas",
        "Foster a proactive approach to organizational well-being and success",
      ],
      image: productImage2,
      color: "#EAB308",
    },
    {
      icon: <FaLightbulb size={48} className="text-teal-500" />,
      title: "Actionable Insights",
      description:
        "Worksense takes you beyond mere data reporting by delivering actionable insights and recommendations. Our platform leverages AI-driven analysis to suggest targeted interventions, enabling you to improve employee sentiment and drive positive business outcomes.",
      points: [
        "Receive personalized recommendations tailored to each team's unique needs",
        "Access suggested action plans to effectively address identified problem areas",
        "Monitor the impact of implemented changes and track progress over time",
      ],
      image: productImage3,
      color: "#14B8A6",
    },
    {
      icon: <FaPiggyBank size={48} className="text-green-500" />,
      title: "Cost Savings",
      description:
        "Experience significant cost savings across various areas of your organization by harnessing the power of Worksense's sentiment analysis and actionable insights.",
      points: [
        "Boost operational efficiency and productivity through data-driven optimization",
        "Reduce employee turnover and associated hiring costs by enhancing engagement and satisfaction",
        "Optimize resource allocation and decision-making based on accurate sentiment data",
        "Achieve measurable ROI and demonstrate the value of your HR initiatives",
      ],
      image: productImage4,
      color: "#10B981",
    },
  ];

  return (
    <section className="py-20 bg-gray-100">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
          Unlock the Power of Data-Driven Decision Making
        </h2>
        {/* Infographic Section */}
        <div className="mb-16 flex justify-center">
          <div className="relative flex items-center justify-between w-full max-w-4xl">
            {features.map((feature, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center"
              >
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className="flex flex-col items-center"
                >
                  <div className="flex items-center justify-center w-16 h-16 bg-white rounded-full shadow-md">
                    {feature.icon}
                  </div>
                  <p className="text-lg font-semibold text-gray-800 mt-4">
                    {feature.title}
                  </p>
                </motion.div>
                {index < features.length && (
                  <motion.div
                    initial={{ opacity: 0, width: 0 }}
                    whileInView={{ opacity: 1, width: "50px" }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.2 + 0.3 }}
                    className="mt-4 mx-2 h-1"
                    style={{
                      backgroundColor: feature.color,
                    }}
                  ></motion.div>
                )}
              </div>
            ))}
          </div>
        </div>
        {/* Features Section */}
        <div className="grid grid-cols-1 gap-12">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              style={{ borderTopColor: feature.color, borderTopWidth: "4px" }}
            >
              <div className="md:flex">
                <div className="md:w-1/2 flex items-center justify-center">
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="w-full h-full object-contain"
                  />
                </div>
                <div className="md:w-1/2 p-8">
                  <div className="flex items-center mb-4">
                    {feature.icon}
                    <h3 className="text-2xl font-bold ml-4 text-gray-800">
                      {feature.title}
                    </h3>
                  </div>
                  <p className="text-lg mb-6 text-gray-700">
                    {feature.description}
                  </p>
                  <ul className="mb-6 space-y-2">
                    {feature.points.map((point, pointIndex) => (
                      <li
                        key={pointIndex}
                        className="flex items-center text-gray-700"
                      >
                        <span className="mr-2" style={{ color: feature.color }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 011.414 0l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        {/* Call to Action Section */}
        <div className="mt-16 flex justify-center items-center flex-col bg-gradient-to-r from-cyan-500 to-blue-500 py-10 rounded-lg text-white">
          <h2 className="text-4xl font-bold mb-8">
            Transform Your Workforce with AI
          </h2>
          <p className="text-xl mb-8">
            Experience cutting-edge analytics and insights to drive your business forward.
          </p>
          <motion.a
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-block px-12 py-6 font-semibold text-xl text-white bg-gradient-to-r from-blue-700 to-blue-900 hover:bg-gradient-to-l focus:outline-none focus:ring-4 focus:ring-blue-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1"
            href="/signup"
            aria-label="Get Started with Worksense"
          >
            Get Started
          </motion.a>
        </div>
      </div>
    </section>
  );
};

export default ProductFeatures;
