import React from "react";

const DistributionBar = ({
  title,
  veryNegative,
  negative,
  neutral,
  positive,
  veryPositive,
  isBurnout = false,
}) => {
  const total = veryNegative + negative + neutral + positive + veryPositive;

  const getPercentage = (value) => (total > 0 ? (value / total) * 100 : 0);

  // Define the tooltip labels
  const tooltipLabels = {
    veryNegative: isBurnout ? "Very Positive" : "Very Negative",
    negative: isBurnout ? "Positive" : "Negative",
    neutral: "Neutral",
    positive: isBurnout ? "Negative" : "Positive",
    veryPositive: isBurnout ? "Very Negative" : "Very Positive",
  };

  // Define segments with their respective percentage and tooltip
  const segments = [
    { type: 'veryNegative', value: veryNegative },
    { type: 'negative', value: negative },
    { type: 'neutral', value: neutral },
    { type: 'positive', value: positive },
    { type: 'veryPositive', value: veryPositive },
  ];

  // Reverse the segments for burnout
  if (isBurnout) {
    segments.reverse();
  }

  const getColorClass = (type) => {
    const colorMap = {
      veryNegative: isBurnout ? "bg-green-600" : "bg-red-600",
      negative: isBurnout ? "bg-green-300" : "bg-red-300",
      neutral: "bg-gray-300",
      positive: isBurnout ? "bg-red-300" : "bg-green-300",
      veryPositive: isBurnout ? "bg-red-600" : "bg-green-600",
    };
    return colorMap[type];
  };

  return (
    <div className="space-y-2">
      <div className="bg-gray-200 rounded-full h-4 w-full flex overflow-hidden">
        {segments.map((segment, index) => {
          const pct = getPercentage(segment.value);
          return (
            <div
              key={segment.type}
              style={{ width: `${pct}%` }}
              className={`${getColorClass(segment.type)} h-full transition-all duration-300 ease-in-out ${
                index === 0 ? 'rounded-l-full' : ''} ${
                index === segments.length - 1 ? 'rounded-r-full' : ''}`}
              title={`${tooltipLabels[segment.type]}: ${pct.toFixed(2)}%`}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default DistributionBar;
