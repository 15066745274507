import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import BambooHrInit from "../components/Integrations/BambooHrInit";
import HiBobInit from "../components/Integrations/HiBobInit";
import Layout from "../containers/Layout";
import { auth, db } from "../firebase";
import * as Constants from "../utils/Constants";
import Loader from "../utils/Loader";
import MattermostInit from "../components/Integrations/MattermostInit";
import { FaQuestionCircle } from "react-icons/fa";
import { saveTeamsData } from "../utils/IntegrationHelper";
import CSVbutton from "../components/Integrations/CSVbutton";
import "react-toggle/style.css";
import { getSelectedCompanyId } from "../utils/companyStorage";
import validatePayment from "../utils/ValidatePayment";
import GroupedIntegrations from "../components/Cards/GroupedIntegrations";
import CSVIntegrationContent from "../components/Integrations/CSVIntegrationContent";

const clientId =
  "146068277190-i4f2dd36fl92f6uhpfr1t6mvnlm7pf0f.apps.googleusercontent.com";
const scope = encodeURIComponent(
  "https://www.googleapis.com/auth/gmail.readonly"
);
const redirectUri = encodeURIComponent(
  "https://oauth-redirect-gmail-vk5ngmgetq-uc.a.run.app"
);
const authUri = "https://accounts.google.com/o/oauth2/v2/auth";

const INITIAL_INTEGRATION_LIST = [
  {
    id: Constants.SLACK,
    name: "Slack",
    type: Constants.COMMUNICATION,
    order: 1,
    icon: "https://cdn-icons-png.flaticon.com/512/2111/2111615.png",
    isActive: false,
    initialized: false,
    description:
      "Slack is an instant messaging program designed by Slack Technologies and owned by Salesforce.",
  },
  {
    id: Constants.TEAMS,
    name: "Teams",
    type: Constants.COMMUNICATION,
    order: 2,
    icon: "https://cdn-icons-png.flaticon.com/512/906/906349.png",
    isActive: false,
    initialized: false,
    adminConsent: false,
    description:
      "Microsoft Teams is a collaborative communication platform that integrates chat, video conferencing, file sharing, and productivity tools to facilitate teamwork in a digital workspace.",
  },
  {
    id: Constants.BAMBOO,
    name: "BambooHR",
    type: Constants.HRIS,
    order: 2,
    icon: "https://leadsbridge.com/wp-content/themes/leadsbridge/img/integration-lg-logos/logo456.png",
    isActive: false,
    initialized: false,
    initialIntegration: <BambooHrInit />,
    description:
      "BambooHR is the complete HR platform that brings all your employee, payroll, time, and benefit information together in one place.",
  },
  {
    id: Constants.HIBOB,
    name: "HiBob",
    type: Constants.HRIS,
    order: 2,
    icon: Constants.INTEGRATION_ICONS[Constants.HIBOB],
    isActive: false,
    initialized: false,
    initialIntegration: <HiBobInit />,
    description:
      "HiBob is an HR platform that helps modern businesses manage their people, processes, and culture.",
  },
  {
    id: Constants.JIRA,
    name: "Jira",
    type: Constants.TICKETING,
    order: 1,
    icon: "https://cdn-icons-png.flaticon.com/512/5968/5968875.png",
    isActive: false,
    initialized: true,
    description:
      "Jira is the #1 software development tool used by agile teams to plan, track, release, report, and automate work across teams.",
  },
  {
    id: Constants.CONFLUENCE,
    name: "Confluence",
    type: Constants.DOCUMENTATION,
    order: 1,
    icon: "https://cdn-icons-png.flaticon.com/512/5968/5968793.png",
    isActive: false,
    initialized: true,
    description:
      "Confluence is a wiki trusted for documentation, project collaboration, Jira integrations, and more.",
  },
  {
    id: Constants.GOOGLE_MEET,
    name: "Google Meet",
    type: Constants.COMMUNICATION,
    order: 3,
    icon: "https://cdn-icons-png.flaticon.com/512/5968/5968552.png",
    isActive: false,
    initialized: true,
    description:
      "Google Meet is one service for secure, high-quality video meetings and calls available for everyone, on any device.",
  },
  {
    id: Constants.GMAIL,
    name: "Gmail",
    type: Constants.MAIL,
    order: 1,
    icon: "https://cdn-icons-png.flaticon.com/512/5968/5968534.png",
    isActive: false,
    initialized: false,
    // initialIntegration: (
    //   <a href={`${authUri}?response_type=code&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&access_type=offline&prompt=consent`}>
    //     <button>Integrate with Gmail</button>
    //   </a>
    // ),
    description:
      "Gmail is a free email service provided by Google. As of 2019, it had 1.5 billion active users worldwide.",
  },
  {
    id: Constants.RIPPLING,
    name: "Rippling",
    type: Constants.HRIS,
    order: 2,
    icon: "https://play-lh.googleusercontent.com/JmTADHoAPMDtXtseUpGpBTxHzeS8og8gxFKFjsz2xdjhMR9P-nC2cnabkOTWCjcSEyDU",
    isActive: false,
    initialized: true,
    description:
      "Rippling is a cloud-based platform that helps you manage your employees' payroll, benefits, expenses, devices, apps, and more in one place.",
  },
  {
    id: Constants.WORKDAY,
    name: "Workday",
    type: Constants.HRIS,
    order: 2,
    icon: "https://seeklogo.com/images/W/workday-logo-4971E992CF-seeklogo.com.png",
    isActive: false,
    initialized: true,
    description:
      "Workday gives organizations of all sizes the power to adapt through finance, HR, planning, spend management, and analytics applications.",
  },
  {
    id: Constants.ZOOM,
    name: "Zoom",
    type: Constants.COMMUNICATION,
    order: 3,
    icon: "https://cdn-icons-png.flaticon.com/512/4401/4401470.png",
    isActive: false,
    initialized: true,
    description:
      "Zoom is a collaboration tool: including video meetings, team chat, VoIP phone, webinars, whiteboard, contact center, and events.",
  },
  {
    id: Constants.GITHUB,
    name: "Github",
    type: Constants.TICKETING,
    order: 1,
    icon: "https://cdn-icons-png.flaticon.com/512/2111/2111432.png",
    isActive: false,
    initialized: true,
    description:
      "GitHub is a trusted platform for collaboration, productivity, and security for developers of all levels and projects.",
  },
  {
    id: Constants.MATTERMOST,
    name: "Mattermost",
    type: Constants.COMMUNICATION,
    order: 3,
    icon: "https://cdn-icons-png.flaticon.com/512/906/906391.png",
    isActive: false,
    initialized: false,
    initialIntegration: <MattermostInit />,
    description:
      "Mattermost is a trusted, open-source platform that supports collaboration, planning, and organizational structure.",
  },
  {
    id: Constants.OUTLOOK,
    name: "Outlook",
    type: Constants.MAIL,
    order: 1,
    icon: "https://cdn-icons-png.flaticon.com/512/732/732223.png",
    isActive: false,
    initialized: true,
    description:
      "Outlook is an email service from Microsoft, with powerful organizational tools and collaboration software.",
  },
  {
    id: Constants.DRIVE,
    name: "Drive",
    type: Constants.FILE_STORAGE,
    order: 1,
    icon: "https://cdn-icons-png.flaticon.com/512/5968/5968523.png",
    isActive: false,
    initialized: true,
    description:
      "Google Drive is a cloud storage service that lets you store and access your files from any device.",
  },
  {
    id: Constants.CSV,
    name: "CSV",
    type: Constants.HRIS,
    order: 1,
    icon: "https://cdn-icons-png.flaticon.com/512/9159/9159105.png",
    isActive: false,
    initialized: false,
    description:
      "A CSV file is widely used for data exchange between applications and for exporting data from databases and spreadsheets.",
  },
];

function Integrations() {
  const [integrationList, setIntegrationList] = useState(INITIAL_INTEGRATION_LIST);
  const [userDisplayName, setUserDisplayName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isDemoUser, setIsDemoUser] = useState(true);
  const [userUid, setUserUid] = useState(null);
  const [slackIntegrationLink, setSlackIntegrationLink] = useState("");
  const [gmailIntegrationLink, setGmailIntegrationLink] = useState("");
  const [teamsIntegrationLink, setTeamsIntegrationLink] = useState("");
  const [teamsAdminConsentLink, setTeamsAdminConsentLink] = useState("");
  const [teamIds, setTeamIds] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const teamsScopes = encodeURIComponent(
    "https://graph.microsoft.com/.default"
  );
  const teamsClientId = "fc3631ba-5a7b-43a5-8bef-170321fda38b";

  const CustomButton = ({ href, children, icon, onClick = undefined }) => {
    return (
      <a
        href={href}
        className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
        onClick={onClick}
      >
        {icon && <img src={icon} alt="" className="mr-2 h-6 w-6" />}
        {children}
      </a>
    );
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const companyId = await getSelectedCompanyId(firebaseUser.uid);
        setUserUid(companyId);
        setUserDisplayName(firebaseUser.displayName);
        setUserEmail(firebaseUser.email);

        const state = encodeURIComponent(firebaseUser.uid);
        const teamsRedirectUri = encodeURIComponent(
          `https://oauth-redirect-teams-vk5ngmgetq-uc.a.run.app`
        );
        const slackOAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=5675402971444.5670120139477&scope=&user_scope=channels:history,channels:read,groups:history,groups:read,im:read,mpim:history,mpim:read,users:read,users:read.email,im:history&state=${state}`;
        const gmailOAuthUrl = `${authUri}?response_type=code&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&state=${state}&access_type=offline&prompt=consent`;
        const teamsAuthUri = `https://login.microsoftonline.com/organizations/adminconsent?client_id=${teamsClientId}&prompt=admin_consent&response_mode=query&redirect_uri=${teamsRedirectUri}&state=${state}&state=admin_false`;
        const teamsAdminConsentUrl = `https://login.microsoftonline.com/organizations/adminconsent?client_id=${teamsClientId}&prompt=admin_consent&response_mode=query&redirect_uri=${teamsRedirectUri}&state=${state}&state=admin_true`;
        setSlackIntegrationLink(slackOAuthUrl);
        setGmailIntegrationLink(gmailOAuthUrl);
        setTeamsIntegrationLink(teamsAuthUri);
        setTeamsAdminConsentLink(teamsAdminConsentUrl);

        fetchIntegrationStates(companyId);

        const hasPaid = await validatePayment(firebaseUser.uid);
        if (!hasPaid) {
          window.location.href = "/onboarding";
        }
      } else {
        window.location.href = "/signin";
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchIntegrationStates = async (companyId) => {
    try {
      const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, companyId);
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();

      if (userData && userData.integrations) {
        const updatedIntegrationList = integrationList.map((integration) => {
          const userDataForIntegration = userData.integrations[integration.id];

          if (userDataForIntegration) {
            if (integration.id === Constants.TEAMS) {
              return {
                ...integration,
                isActive: userDataForIntegration.isActive,
                initialized: userDataForIntegration.initialized,
                adminConsent: userDataForIntegration.adminConsent,
              };
            }
            return {
              ...integration,
              isActive: userDataForIntegration.isActive,
              initialized: userDataForIntegration.initialized,
            };
          }
          return integration;
        });

        setIntegrationList(updatedIntegrationList);
      }

      if (userData && typeof userData.isDemoUser !== "undefined") {
        setIsDemoUser(userData.isDemoUser);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateIntegrationStatus = async (integrationId, currentState) => {
    try {
      setIntegrationList((prevList) =>
        prevList.map((i) =>
          i.id === integrationId ? { ...i, isActive: !i.isActive } : i
        )
      );

      const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, userUid);
      const integrationData = {
        integrations: {
          [integrationId]: {
            isActive: !currentState,
            initialized: true,
          },
        },
      };

      await setDoc(userDocRef, integrationData, { merge: true });
    } catch (error) {
      console.error("Error updating integration status:", error);
      setIntegrationList((prevList) =>
        prevList.map((i) =>
          i.id === integrationId ? { ...i, isActive: currentState } : i
        )
      );
    }
  };

  const handleTeamIdsChange = (event) => {
    setTeamIds(event.target.value);
  };

  const renderInitialIntegration = (integration) => {
    if (!integration.initialized) {
      switch (integration.id) {
        case Constants.GMAIL:
          return (
            <CustomButton
              href={gmailIntegrationLink}
              icon="https://cdn-icons-png.flaticon.com/512/5968/5968534.png"
            >
              Integrate with Gmail
            </CustomButton>
          );
        case Constants.TEAMS:
          return (
            <>
              <div className="flex flex-col">
                <label
                  htmlFor="Team IDs"
                  className="text-sm font-medium text-gray-700 flex items-center"
                >
                  Team IDs
                  <FaQuestionCircle
                    className="ml-2 text-lg text-gray-500"
                    title="You can find your team IDs by clicking ... next to team and clicking 'Get Link to Team'. Copy the value of 'groupId'"
                  />
                </label>
                <input
                  type="text"
                  value={teamIds}
                  onChange={handleTeamIdsChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter Your Team IDs, Separated by commas and no spaces."
                />
              </div>
              <div style={{ height: "10%" }} />
              <CustomButton
                icon="https://cdn-icons-png.flaticon.com/512/906/906349.png"
                onClick={() => {
                  if (!teamIds) {
                    alert("Team ID field cannot be null");
                  } else {
                    saveTeamsData(teamIds);
                    window.location.href = teamsIntegrationLink;
                  }
                }}
              >
                Add Application To Teams Space
              </CustomButton>
            </>
          );
        case Constants.SLACK:
          return (
            <CustomButton
              href={slackIntegrationLink}
              icon="https://cdn-icons-png.flaticon.com/512/2111/2111615.png"
            >
              Integrate with Slack
            </CustomButton>
          );
        case Constants.CSV:
          return <CSVIntegrationContent/>;
        default:
          return integration.initialIntegration;
      }
    }
    return null;
  };

  const validateIntegrationList = (list) => {
    return list.map(integration => ({
      ...integration,
      type: integration.type || 'Other'
    }));
  };

  return (
    <Layout userDisplayName={userDisplayName} userEmail={userEmail}>
      <Loader loading={isLoading} size={50} color={"#123abc"} loadingText={"Loading Integrations"}>
        <GroupedIntegrations
          integrationList={validateIntegrationList(integrationList.filter(i => isDemoUser || Constants.LIVE_INTEGRATIONS.includes(i.id)))}
          isDemoUser={isDemoUser}
          onToggle={updateIntegrationStatus}
          renderInitialIntegration={renderInitialIntegration}
        />
      </Loader>
    </Layout>
  );
}

export default Integrations;