import React, { useState, useCallback } from "react";
import Loader from "../utils/Loader";
import "react-toggle/style.css";
import { auth } from "../firebase";
import { getFilteredEmployeeQuery } from "../utils/FilterUtils";
import { getFilterDatesConditions } from "../utils/DateRange";
import { getApiUrl } from "../utils/apiConfig";
import Layout from "../containers/Layout";
import BarData from "../components/BarData";
import FilterOptions from "../components/FilterOptions";
import useAuthenticatedUser from "../hooks/AuthenticateUser";

export default function TeamCharts() {
  const { user, sandboxUserQueryParameter, isLoading } = useAuthenticatedUser();
  const [filters, setFilters] = useState({ scoreType: "Sentiment" });
  const [teamChartData, setTeamChartData] = useState(null);
  const [scoreType, setScoreType] = useState("Sentiment");

  const handleScoreTypeChange = useCallback((newScoreType) => {
    setScoreType(newScoreType);
  }, []);

  async function getChannelScores(companyId, filters) {
    setTeamChartData(null);

    const queryConditions = await getFilteredEmployeeQuery(companyId, filters);
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );
    const score_type = filters.scoreType.toLowerCase() + "_score";

    const idToken = await auth.currentUser.getIdToken();
    const endpointUrl = getApiUrl("get-department-scores");
    const url = `${endpointUrl}?company_uuid=${companyId}${queryConditions.join(
      ""
    )}${filterDateConditions.join(
      ""
    )}&score_category=${score_type}${sandboxUserQueryParameter}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      mode: "cors",
    })
      .then((response) => response.json())
      .then((response) => {
        const digestedMap = response
          .map((x) => ({
            Department: x["department"],
            average: x["scores"][filters.scoreType.toLowerCase() + "_score"],
          }))
          .filter((x) => x.average !== null);
        const dataLength = digestedMap.length;
        const data = {
          scoreByTopData: digestedMap.slice(0, 10),
          scoreByBottomData: digestedMap
            .slice(dataLength - 10, dataLength)
            .reverse(),
        };
        setTeamChartData(data);
      })
      .catch((error) => {
        setTeamChartData([]);
      });
  }

  const handleFilterChange = useCallback(
    (filters) => {
      const currentCompanyId = user?.uid;
      if (currentCompanyId === "aGlJ700yjLO4OtmDvx5GwvmOH5n1") {
        filters = { ...filters, location: "Belgrade" };
      }

      if (filters.scoreType && filters.scoreType !== scoreType) {
        handleScoreTypeChange(filters.scoreType);
      }

      getChannelScores(currentCompanyId, filters);
      setFilters(filters);
    },
    [scoreType, handleScoreTypeChange, user]
  );

  return (
    <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
      <Loader
        loading={isLoading}
        size={50}
        color={"#123abc"}
        loadingText={"Generating Team Data"}
      >
        <FilterOptions
          user={user}
          companyUid={user?.uid}
          onFilterChange={handleFilterChange}
        />
        <div className="flex flex-row h-screen p-4 space-x-4">
          <div className="flex-grow w-full md:w-1/2 max-w-[600px]">
            <Loader
              loading={!teamChartData}
              componentLoading={true}
              size={30}
              color={"#4a90e2"}
              loadingText={"Top Team Data"}
            >
              <BarData
                title={`Top ${
                  filters && filters.scoreType ? filters.scoreType : "Unknown"
                } Scores`}
                dataSet={teamChartData?.scoreByTopData || []}
                color="#4a90e2"
              />
            </Loader>
          </div>
          <div className="flex-grow w-full md:w-1/2 max-w-[600px]">
            <Loader
              loading={!teamChartData}
              componentLoading={true}
              size={30}
              color={"#e25c4a"}
              loadingText={"Bottom Team Data"}
            >
              <BarData
                title={`Bottom ${
                  filters && filters.scoreType ? filters.scoreType : "Unknown"
                } Scores`}
                dataSet={teamChartData?.scoreByBottomData || []}
                color="#e25c4a"
              />
            </Loader>
          </div>
        </div>
      </Loader>
    </Layout>
  );
}
