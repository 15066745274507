import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Loader from "../../utils/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MinimalLineChart = ({ title, dataSet, isLoading }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        borderColor: "rgb(59, 130, 246)", // Tailwind blue-500
        tension: 0.3,
        spanGaps: true,
      },
    ],
  });

  useEffect(() => {
    if (dataSet && dataSet.labels && dataSet.datasets) {
      setChartData({
        labels: dataSet.labels,
        datasets: dataSet.datasets.map((dataset) => ({
          ...dataset,
          borderColor: "rgb(59, 130, 246)", // Tailwind blue-500
          backgroundColor: "rgba(59, 130, 246, 0.1)",
          tension: 0.3,
          spanGaps: true,
        })),
      });
    }
  }, [dataSet]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleColor: "white",
        bodyColor: "white",
        borderColor: "white",
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 5,
          font: {
            size: 12,
          },
        },
      },
      y: {
        grid: {
          color: "rgba(0, 0, 0, 0.05)",
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 5,
      },
      line: {
        borderWidth: 3,
      },
    },
  };

  if (isLoading) {
    return (
      <Loader
        loading={true}
        size={50}
        color={"#123abc"}
        loadingText={"Loading Trend Data"}
      />
    );
  }

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <Line options={options} data={chartData} />
    </div>
  );
};

export default MinimalLineChart;