import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import * as Constants from "../utils/Constants";
import Logo from "../images/logos/WorksenseBigger.png";
import { motion } from "framer-motion";
import { AnimatedBackground, SparkleEffect } from "../utils/Animations";
import Payment from "../components/OnboardingComponents/Payment";
import CompanyDomain from "../components/OnboardingComponents/CompanyDomain";
import IntegrationsList from "../components/OnboardingComponents/IntegrationsList";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import LogoutButton from "../components/OnboardingComponents/LogOutButton";

import {
  itemVariants,
  containerVariants,
} from "../components/OnboardingComponents/AnimationsVariants";
import validatePayment from "../utils/ValidatePayment";
import Introduction from "../components/OnboardingComponents/Introduction";
import ProgressBar from "../components/OnboardingComponents/ProgressBar";
import CompanyName from "../components/OnboardingComponents/CompanyName";
import CompanySize from "../components/OnboardingComponents/CompanySize";

const Onboarding = () => {
  const [user, setUser] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState("");
  const [step, setStep] = useState(1);
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);
  const [customIntegrations, setCustomIntegrations] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (
            userData.companyName &&
            userData.companyDomain &&
            userData.numberOfEmployees &&
            userData.isOnboarded &&
            userData.isPaid
          ) {
            window.location.href = "/dashboard";
          }
        }
      } else {
        window.location.href = "/signin";
      }
    });

    return () => unsubscribe();
  }, []);

  const validateStep = () => {
    const newErrors = {};

    if (step === 2) {
      if (!companyName.trim()) {
        newErrors.companyName = "Company name is required";
      } else if (companyName !== companyName.trim()) {
        newErrors.companyName =
          "Company name should not have leading or trailing spaces";
      }
    }

    if (step === 3) {
      const trimmedDomain = companyDomain.trim();
      if (!trimmedDomain) {
        newErrors.companyDomain = "Company domain is required";
      } else if (trimmedDomain !== companyDomain) {
        newErrors.companyDomain =
          "Domain cannot contain leading or trailing spaces.";
      } else if (
        !/^(?:[a-zA-Z0-9-]+\.)+(com|org|net|edu|gov|co|us|io|biz)$/.test(
          trimmedDomain
        )
      ) {
        newErrors.companyDomain =
          "Invalid domain. Must be .com, .org, .net, .edu, .gov, .co, .us, .io, .ai, or .biz";
      }
    }

    if (step === 4 && !numberOfEmployees) {
      newErrors.numberOfEmployees = "Number of employees is required";
    }

    if (
      step === 5 &&
      selectedIntegrations.length === 0 &&
      !customIntegrations
    ) {
      newErrors.integrations = "At least one integration is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (!validateStep()) return;

    if (step === 5) {
      await saveCompanyInfo();
      const hasPaid = await validatePayment(user.uid);
      if (hasPaid) {
        window.location.href = "/onboarding/complete";
      } else {
        setStep(step + 1);
      }
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const saveCompanyInfo = async () => {
    const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, user.uid);
    await setDoc(
      userDocRef,
      {
        companyName,
        companyDomain: `@${companyDomain}`,
        numberOfEmployees,
        integrationsOfInterest: [
          ...selectedIntegrations,
          ...customIntegrations.split(",").map((item) => item.trim()),
        ],
      },
      { merge: true }
    );
  };

  const totalSteps = 7;
  const progressWidth = ((step - 1) / (totalSteps - 1)) * 100;

  return (
    <div className="bg-gradient-to-tr from-cyan-500 via-blue-400 to-blue-700 min-h-screen flex flex-col items-center relative">
      <AnimatedBackground />
      <div className="flex flex-col items-center justify-center w-full max-w-screen-xl px-6 py-6">
        <div className="flex items-center justify-between w-full mb-8 relative">
          <div className="flex-grow flex justify-center">
            <img src={Logo} alt="Logo" className="h-24 w-auto" />
          </div>

          <div className="absolute top-0 right-0 flex items-center space-x-2 mt-2 mr-2">
            <LogoutButton />
          </div>
        </div>

        <div className="flex items-center justify-center w-full">
          <motion.div
            className="bg-white rounded-lg p-10 max-w-xl w-full z-10 flex flex-col"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            style={{
              boxShadow:
                "0 20px 40px rgba(0, 0, 0, 0.4), 0 8px 16px rgba(0, 0, 0, 0.3)",
              minHeight: "auto",
              position: "relative",
            }}
          >
            <div className="flex flex-col flex-grow">
              <div className="flex-grow">
                {step === 1 && <Introduction handleNext={handleNext} />}
                {step === 2 && (
                  <CompanyName
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    errors={errors}
                  />
                )}
                {step === 3 && (
                  <CompanyDomain
                    companyDomain={companyDomain}
                    setCompanyDomain={setCompanyDomain}
                    errors={errors}
                  />
                )}
                {step === 4 && (
                  <CompanySize
                    numberOfEmployees={numberOfEmployees}
                    setNumberOfEmployees={setNumberOfEmployees}
                    errors={errors}
                  />
                )}
                {step === 5 && (
                  <IntegrationsList
                    selectedIntegrations={selectedIntegrations}
                    setSelectedIntegrations={setSelectedIntegrations}
                    customIntegrations={customIntegrations}
                    setCustomIntegrations={setCustomIntegrations}
                    errors={errors}
                  />
                )}
                {step === 6 && (
                  <Payment numberOfEmployees={numberOfEmployees} user={user} />
                )}
              </div>
              <div
                className={`flex ${
                  step === 1 ? "justify-end" : "justify-between"
                } mt-6 items-center`}
              >
                {step > 1 && (
                  <IconButton
                    color="primary"
                    onClick={handleBack}
                    aria-label="Back"
                  >
                    <ArrowBack />
                    <span className="ml-2 text-sm">Back</span>
                  </IconButton>
                )}

                {step < 6 && (
                  <IconButton
                    color="primary"
                    onClick={handleNext}
                    aria-label="Next"
                  >
                    <span className="mr-2 text-sm">
                      {step === 5 ? "Proceed to Payment" : "Next"}
                    </span>
                    <ArrowForward />
                  </IconButton>
                )}
              </div>
              <ProgressBar
                progressWidth={progressWidth}
                totalSteps={totalSteps}
                step={step}
              />
            </div>
          </motion.div>
        </div>
      </div>
      <SparkleEffect />
    </div>
  );
};

export default Onboarding;
