import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import * as Constants from "../utils/Constants";

export const SignUpForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isSigningUp, setIsSigningUp] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && !isSigningUp) {
        // Check if the user has completed onboarding
        const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && userDoc.data().isOnboarded) {
          window.location.href = "/integrations";
        } else {
          window.location.href = "/onboarding";
        }
      }
    });

    return () => unsubscribe();
  }, [isSigningUp]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsSigningUp(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await initUser(user);
      window.location.href = "/onboarding";
    } catch (error) {
      const errorMessage = error.message;
      setError(errorMessage);
    } finally {
      setIsSigningUp(false);
    }
  };

  async function initUser(user) {
    const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, user.uid);
    const userData = {
      name: user.displayName,
      email: user.email,
      isDemoUser: false,
      userTier: "Base",
      isOnboarded: false, // Ensure isOnboarded is set to false initially
      isPaid: false,
    };

    await setDoc(userDocRef, userData, { merge: true }); // Use merge: true to merge with existing document
  }

  return (
    <section className="bg-gray-50 dark:bg-gray-900 min-h-screen flex justify-center items-center">
      <div className="w-full max-w-md p-6 space-y-8 bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          Sign up for an account
        </h2>
        <form className="mt-8 space-y-6" onSubmit={handleSignUp}>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@company.com"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Choose a password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="••••••••"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="submit"
            className="w-full px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Create an account
          </button>
          <div className="text-sm font-medium text-gray-900 dark:text-white">
            Already have an account?{" "}
            <a
              href="/signin"
              className="text-blue-600 hover:underline dark:text-blue-500"
            >
              Sign in
            </a>
          </div>
        </form>
      </div>
    </section>
  );
};
