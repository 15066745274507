import { motion } from "framer-motion";

const FAQSection = () => {
  const faqs = [
    {
      question: "How important is sentiment to companies?",
      answer:
        "Sentiment is crucial for productivity and retention. Companies invest heavily to understand and improve sentiment, directly impacting their bottom line.",
    },
    {
      question:
        "How can this tool lead directly to better retention or productivity?",
      answer:
        "Our tool provides actionable insights to leaders, helping them understand and improve employee sentiment, leading to happier, more productive employees who stay longer.",
    },
    {
      question: "What makes Worksense different?",
      answer:
        "Worksense captures sentiment automatically, removing the need for manual surveys. This provides real-time, unbiased insights, allowing companies to act quickly and improve employee engagement.",
    },
    {
      question: "How fast is it to integrate?",
      answer:
        "It can take less than 20 minutes to integrate Worksense with your existing tools and start capturing valuable insights.",
    },
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-100 to white">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold mb-12 text-center">
          Frequently Asked Questions
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05 }}
              className="bg-white p-8 rounded-lg shadow-md border-2 border-gray-200"
            >
              <h3 className="text-2xl font-bold mb-4">{faq.question}</h3>
              <p className="text-lg">{faq.answer}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
