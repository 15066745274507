import React from "react";

const TermsOfService = () => {
  return (
    <section className="bg-white text-gray-900 pt-16">
      <div className="max-w-screen-xl mx-auto flex items-center px-4 py-8">
        <div className="prose max-w-none">
          <h1 className="text-3xl font-semibold text-[#14576E] text-center">
            TERMS OF SERVICE
          </h1>
          <p className="text-lg text-black">Last Updated: 2 MARCH 2024</p>

          <p className="text-black">
            Please read these Terms of Service (this “<u>Agreement</u>”)
            carefully. Your use of the Service (as defined below) constitutes
            your consent to this Agreement.
          </p>

          <p className="text-black">
            This Agreement is between you and Worksense, Inc. (“<u>Worksense</u>
            ” or “<u>we</u>” or “<u>us</u>” or “<u>our</u>”) concerning your use
            of (including any access to) our website located at{" "}
            <a href="https://worksenseai.com/" className="text-[#14576E]">
              https://worksenseai.com/
            </a>{" "}
            (the “<u>Site</u>”) as well as all related web sites, networks,
            embeddable widgets, downloadable software, mobile applications
            (including tablet applications, the “<u>App</u>”), and other
            services provided by us and on which a link to this Agreement is
            displayed (collectively, together with the Site, our “<u>Service</u>
            ”). This Agreement hereby incorporates by this reference any
            additional terms and conditions posted by Worksense through the
            Service, or otherwise made available to you by Worksense.
          </p>

          <p className="text-black">
            USE OF THE SERVICE BY ANYONE UNDER THE AGE OF 16 IS PROHIBITED. BY
            USING THE SERVICE, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO ENTER
            INTO THIS AGREEMENT, OR, IF YOU ARE NOT, THAT YOU HAVE OBTAINED
            PARENTAL OR GUARDIAN CONSENT TO ENTER INTO THIS AGREEMENT.
          </p>

          <p className="text-black">
            THIS AGREEMENT CONTAINS (AMONG OTHER THINGS) A MANDATORY ARBITRATION
            PROVISION THAT, AS FURTHER SET FORTH IN SECTION 18 BELOW, REQUIRES
            THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES,
            RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS, OR CLASS
            ACTIONS OF ANY KIND.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">1. Changes</h2>
          <p className="text-black">
            We may change this Agreement from time to time by notifying you of
            such changes by any reasonable means, including by posting a revised
            Agreement through the Service. Any such changes will not apply to
            any dispute between you and us arising prior to the date on which we
            posted the revised Agreement incorporating such changes, or
            otherwise notified you of such changes.
          </p>

          <p className="text-black">
            Your use of the Service following any changes to this Agreement will
            constitute your acceptance of such changes. The “Last Updated”
            legend above indicates when this Agreement was last changed. We may,
            at any time and without liability, modify or discontinue all or part
            of the Service (including access to the Service via any third-party
            links); charge, modify or waive any fees required to use the
            Service; or offer opportunities to some or all Service users.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            2. Information Submitted Through the Service
          </h2>
          <p className="text-black">
            Your submission of information through the Service is governed by
            Worksense’s Privacy Policy, located at{" "}
            <a
              href="https://worksenseai.com/privacy-policy"
              className="text-[#14576E]"
            >
              https://worksenseai.com/privacy-policy
            </a>{" "}
            (the “<u>Privacy Policy</u>”). You represent and warrant that any
            information you provide is and will remain accurate and complete,
            and that you will maintain and update such information as needed.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">3. Input and Output</h2>
          <p className="text-black">
            You agree and instruct that we may process inputs provided by you
            (collectively, “<u>Input</u>”) and generate output based on the
            Input (“<u>Output</u>”) in connection with the AI features of the
            Service, to perform the Service, to maintain and provide the
            Service, to train the machine learning models used to provide the
            Service, and to develop and improve Worksense technologies. You will
            ensure that all Input and use of the Service and Output will not:
            (a) violate the terms of this Agreement or violate applicable law;
            or (b) infringe, violate, or misappropriate any of Worksense’s
            rights or the rights of any third party. You acknowledge that due to
            the nature of machine learning and the technology powering the
            Service, Output may not be unique, and the Service may generate, to
            or for Worksense or a third party, output that is the same as or
            similar to Output. You are responsible for all Input and use of the
            Outputs. You represent and warrant that you have all rights,
            licenses, and permissions required to provide Input to the Services.
            You are solely responsible for evaluating the Output for accuracy
            and appropriateness for your use case.
          </p>

          {/* Placeholder for brevity */}
          <h2 className="text-2xl text-[#14576E] mt-6">
            4. Improving the Service
          </h2>
          <p className="text-black">
            You hereby irrevocably grant all rights and permissions in or
            relating to the text, images, or other content, that you input into
            the Service or that are collected, downloaded, or otherwise
            received, directly or indirectly, by or through the Services or that
            are derived from the processing of such documents, information,
            graphics, data, materials, and other content by or through the
            Services (collectively, “<u>Customer Data</u>”), as are necessary or
            useful to Worksense to enforce this Agreement and exercise
            Worksense’s rights and perform Worksense’s obligations hereunder,
            and otherwise as required or authorized by law. Without limiting the
            generality of the foregoing, Worksense may generate, or derive
            information, data, and content from Worksense’s processing of such
            Customer Data for general product research and development purposes,
            including, but not limited to, creating new products, services, or
            components not specific to a customer (such purposes, “
            <u>Product Development</u>”, and the information, data, and content
            derived therefrom, “<u>Product Development Data</u>”). You will be
            responsible for obtaining all rights, permissions, and
            authorizations to provide Customer Data to Worksense for use as
            contemplated under this Agreement. Except for the rights and
            licenses expressly granted under this Agreement, nothing contained
            in this Agreement will be construed as granting Worksense any right,
            title, or interest in the Customer Data. You hereby grant Worksense
            a non-exclusive, perpetual, irrevocable, fully-paid-up, royalty
            free, worldwide right and license to process and otherwise exploit
            (i) statistical and other aggregated data derived from your use of
            the Service (the “<u>Aggregated Data</u>”), and (ii) Product
            Development Data, in each case ((i) and (ii)), for Worksense’s
            business purposes, including Product Development, so long as any
            such Aggregated Data and any such Product Development Data are
            combined with similar data from Worksense’s other customers and do
            not include (directly or by inference) any information identifying
            you or other identifiable individual or protected health information
            (PHI), or otherwise reveal your confidential information or PHI
            except as otherwise expressly permitted under this Agreement or in
            accordance with applicable law. For clarity, Customer Data does not
            include Aggregated Data or Product Development Data, and all such
            Aggregated Data and Product Development Data will not be considered
            your confidential information.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            5. Jurisdictional Issues
          </h2>
          <p className="text-black">
            The Service is controlled or operated (or both) from the United
            States, and is not intended to subject Worksense to any non-U.S.
            jurisdiction or law. The Service may not be appropriate or available
            for use in some non-U.S. jurisdictions. Any use of the Service is at
            your own risk, and you must comply with all applicable laws, rules,
            and regulations in doing so. We may limit the Service’s availability
            at any time, in whole or in part, to any person, geographic area or
            jurisdiction that we choose.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">6. Rules of Conduct</h2>
          <p className="text-black">
            In connection with the Service, you must not:
          </p>
          <ul className="text-black">
            <li>
              Post, transmit or otherwise make available through or in
              connection with the Service any materials that are or may be: (a)
              threatening, harassing, degrading, hateful or intimidating, or
              otherwise fail to respect the rights and dignity of others; (b)
              defamatory, libelous, fraudulent or otherwise tortious; (c)
              obscene, indecent, pornographic or otherwise objectionable; or (d)
              protected by copyright, trademark, trade secret, right of
              publicity or privacy or any other proprietary right, without the
              express prior written consent of the applicable owner.
            </li>
            <li>
              Post, transmit or otherwise make available through or in
              connection with the Service any virus, worm, Trojan horse, Easter
              egg, time bomb, spyware or other computer code, file or program
              that is or is potentially harmful or invasive or intended to
              damage or hijack the operation of, or to monitor the use of, any
              hardware, software, or equipment (each, a “<u>Virus</u>”).
            </li>
          </ul>
          <ul className="text-black">
            <li>
              Use the Service for any commercial purpose, or for any purpose
              that is fraudulent or otherwise tortious or unlawful.
            </li>
            <li>Harvest or collect information about users of the Service.</li>
            <li>
              Interfere with or disrupt the operation of the Service or the
              servers or networks used to make the Service available, including
              by hacking or defacing any portion of the Service; or violate any
              requirement, procedure, or policy of such servers or networks.
            </li>
            <li>
              Restrict or inhibit any other person from using the Service.
            </li>
            <li>
              Reproduce, modify, adapt, translate, create derivative works of,
              sell, rent, lease, loan, timeshare, distribute or otherwise
              exploit any portion of (or any use of) the Service except as
              expressly authorized herein, without Worksense’s express prior
              written consent.
            </li>
            <li>
              Reverse engineer, decompile or disassemble any portion of the
              Service, except where such restriction is expressly prohibited by
              applicable law.
            </li>
            <li>
              Attempt to de-anonymize or attempt to identify any individual data
              from any aggregate data provided through the Services.
            </li>
            <li>
              Remove any copyright, trademark, or other proprietary rights
              notice from the Service.
            </li>
            <li>
              Frame or mirror any portion of the Service, or otherwise
              incorporate any portion of the Service into any product or
              service, without Worksense’s express prior written consent.
            </li>
            <li>Systematically download and store Service content.</li>
            <li>
              Use any robot, spider, Service search/retrieval application, or
              other manual or automatic device to retrieve, index, “scrape,”
              “data mine” or otherwise gather Service content, or reproduce or
              circumvent the navigational structure or presentation of the
              Service, without Worksense’s express prior written consent.
            </li>
            <li>
              Use, or allow third parties to use, any Output or any other
              content, data, or other information received or derived from the
              Service: to (a) directly or indirectly create, train, test, or
              otherwise improve any machine learning algorithms or artificial
              intelligence systems; (b) to develop foundation models or other
              large scale models that compete with Worksense or the Service or
              other features of the Service; (c) to mislead any person that
              Output from the Service was solely human generated; or (d) in a
              manner that violates any documentation, services usage guidelines,
              or other parameters or instructions of Worksense.
            </li>
          </ul>
          <p className="text-black">
            You are responsible for obtaining, maintaining, and paying for all
            hardware and all telecommunications and other services needed to use
            the Service.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">7. Support</h2>
          <p className="text-black">
            We are under no obligation to provide support for the Service.
            Instances where we may offer support, the support will be subject to
            published policies. Support is conducted through email at{" "}
            <a href="mailto:arshan@worksenseai.com">arshan@worksenseai.com</a>.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            8. Registration; User Names and Passwords
          </h2>
          <p className="text-black">
            You may need to register to use all or part of the Service. We may
            reject, or require that you change, any user name, password or other
            information that you provide to us in registering. Your user name
            and password are for your personal use only and should be kept
            confidential; you, and not Worksense, are responsible for any use or
            misuse of your user name or password, and you must promptly notify
            us of any confidentiality breach or unauthorized use of your user
            name or password, or your Service account
          </p>
          <h2 className="text-2xl text-[#14576E] mt-6">9. Feedback</h2>
          <p className="text-black">
            If you provide to us any ideas, proposals, suggestions or other
            materials (“<u>Feedback</u>”), whether related to the Service or
            otherwise, you hereby acknowledge and agree that such Feedback is
            not confidential, and that your provision of such Feedback is
            gratuitous, unsolicited and without restriction, and does not place
            Worksense under any fiduciary or other obligation. You hereby grant
            to us a worldwide, royalty-free, fully paid-up, non-exclusive,
            perpetual, irrevocable, transferable and fully sublicensable
            (through multiple tiers) license, without additional consideration
            to you or any third party, to reproduce, distribute, perform and
            display (publicly or otherwise), create derivative works of, adapt,
            modify and otherwise use, analyze and exploit Feedback, in any
            format or media now known or hereafter developed, and for any
            purpose (including promotional purposes, such as testimonials).
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">10. Monitoring</h2>
          <p className="text-black">
            We may (but have no obligation to) monitor, evaluate, analyze,
            alter, or remove your access to or use of the Service. We may
            disclose information regarding your access to and use of the
            Service, and the circumstances surrounding such access and use, to
            anyone for any reason or purpose.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            11. Your Limited Rights
          </h2>
          <p className="text-black">
            Subject to your compliance with this Agreement, and solely for so
            long as you are permitted by Worksense to use the Service, you may
            view one (1) copy of any portion of the Service to which we provide
            you access under this Agreement, on any single device, solely for
            your personal, non-commercial use. The App is licensed (not sold) to
            end users. Subject to your compliance with this Agreement, and
            solely for so long as you are permitted by Worksense to use the App,
            we hereby permit you, on a limited, non-exclusive, revocable,
            non-transferable, non-sublicensable basis, to install and use the
            App on a mobile device that you own or control, solely for your
            personal, non-commercial use. If you fail to comply with any of the
            terms or conditions of this Agreement, you must immediately cease
            using the App and remove (that is, uninstall and delete) the App
            from your mobile device.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            12. Worksense’s Proprietary Rights
          </h2>
          <p className="text-black">
            We and our suppliers own the Service, which is protected by
            proprietary rights and laws. All trade names, trademarks, service
            marks and logos on the Service not owned by us are the property of
            their respective owners. You may not use our trade names,
            trademarks, service marks or logos in connection with any product or
            service that is not ours, or in any manner that is likely to cause
            confusion. Nothing contained on the Service should be construed as
            granting any right to use any trade names, trademarks, service marks
            or logos without the express prior written consent of the owner.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            13. Third Party Materials; Links
          </h2>
          <p className="text-black">
            Certain Service functionality may make available access to
            information, products, services, and other materials made available
            by third parties (“<u>Third Party Materials</u>”), or allow for the
            routing or transmission of such Third Party Materials, including via
            links. By using such functionality, you are directing us to access,
            route and transmit to you the applicable Third Party Materials.
          </p>
          <p className="text-black">
            We neither control nor endorse, nor are we responsible for, any
            Third Party Materials, including the accuracy, validity, timeliness,
            completeness, reliability, integrity, quality, legality, usefulness
            or safety of Third Party Materials, or any intellectual property
            rights therein. Certain Third Party Materials may, among other
            things, be inaccurate, misleading, or deceptive. Nothing in this
            Agreement will be deemed to be a representation or warranty by
            Worksense with respect to any Third Party Materials. We have no
            obligation to monitor Third Party Materials, and we may block or
            disable access to any Third Party Materials (in whole or part)
            through the Service at any time. In addition, the availability of
            any Third Party Materials through the Service does not imply our
            endorsement of, or our affiliation with, any provider of such Third
            Party Materials, nor does such availability create any legal
            relationship between you and any such provider.
          </p>
          <p className="text-black">
            YOUR USE OF THIRD PARTY MATERIALS IS AT YOUR OWN RISK AND IS SUBJECT
            TO ANY ADDITIONAL TERMS, CONDITIONS AND POLICIES APPLICABLE TO SUCH
            THIRD PARTY MATERIALS (SUCH AS TERMS OF SERVICE OR PRIVACY POLICIES
            OF THE PROVIDERS OF SUCH THIRD PARTY MATERIALS).
          </p>
          <h2 className="text-2xl text-[#14576E] mt-6">
            14. DISCLAIMER OF WARRANTIES
          </h2>
          <p className="text-black">
            TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) THE
            SERVICE AND ANY THIRD PARTY MATERIALS ARE MADE AVAILABLE TO YOU ON
            AN “AS IS,” “WHERE IS” AND “WHERE AVAILABLE” BASIS, WITHOUT ANY
            WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY; AND
            (B) WORKSENSE DISCLAIMS ALL WARRANTIES WITH RESPECT TO THE SERVICE
            AND ANY THIRD PARTY MATERIALS, INCLUDING THE WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT
            AND TITLE. ALL DISCLAIMERS OF ANY KIND (INCLUDING IN THIS SECTION 14
            AND ELSEWHERE IN THIS AGREEMENT) ARE MADE FOR THE BENEFIT OF BOTH
            WORKSENSE AND ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS,
            DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, REPRESENTATIVES,
            LICENSORS, SUPPLIERS AND SERVICE PROVIDERS (COLLECTIVELY, THE “
            <u>AFFILIATED ENTITIES</u>”), AND THEIR RESPECTIVE SUCCESSORS AND
            ASSIGNS.
          </p>
          <p className="text-black">
            While we try to maintain the timeliness, integrity, and security of
            the Service, we do not guarantee that the Service is or will remain
            updated, complete, correct or secure, or that access to the Service
            will be uninterrupted. The Service may include inaccuracies, errors
            and materials that violate or conflict with this Agreement.
            Additionally, third parties may make unauthorized alterations to the
            Service. If you become aware of any such alteration, contact us at
            arshan@worksenseai.com with a description of such alteration and its
            location on the Service.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            15. LIMITATION OF LIABILITY
          </h2>
          <p className="text-black">
            TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) WORKSENSE
            WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
            SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES OF ANY KIND, UNDER ANY
            CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER
            THEORY, INCLUDING DAMAGES FOR LOSS OF PROFITS, USE OR DATA, LOSS OF
            OTHER INTANGIBLES, LOSS OF SECURITY OF SUBMISSIONS (INCLUDING
            UNAUTHORIZED INTERCEPTION BY THIRD PARTIES OF ANY SUBMISSIONS), EVEN
            IF ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES;
            (B) WITHOUT LIMITING THE FOREGOING, WORKSENSE WILL NOT BE LIABLE FOR
            DAMAGES OF ANY KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE
            THE SERVICE OR FROM ANY THIRD PARTY MATERIALS, INCLUDING FROM ANY
            VIRUS THAT MAY BE TRANSMITTED IN CONNECTION THEREWITH; (C) YOUR SOLE
            AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SERVICE OR ANY
            THIRD PARTY MATERIALS IS TO STOP USING THE SERVICE; AND (D) THE
            MAXIMUM AGGREGATE LIABILITY OF WORKSENSE FOR ALL DAMAGES, LOSSES AND
            CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE)
            OR OTHERWISE, WILL BE $1.00. ALL LIMITATIONS OF LIABILITY OF ANY
            KIND (INCLUDING IN THIS SECTION 15 AND ELSEWHERE IN THIS AGREEMENT)
            ARE MADE FOR THE BENEFIT OF BOTH WORKSENSE AND THE AFFILIATED
            ENTITIES, AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">16. Indemnity</h2>
          <p className="text-black">
            To the fullest extent permitted under applicable law, you agree to
            defend, indemnify and hold harmless Worksense and the Affiliated
            Entities, and their respective successors and assigns, from and
            against all claims, liabilities, damages, judgments, awards, losses,
            costs, expenses and fees (including attorneys’ fees) arising out of
            or relating to (a) your use of, or activities in connection with,
            the Service (including all Inputs and Outputs); and (b) any
            violation or alleged violation of this Agreement by you.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">17. Termination</h2>
          <p className="text-black">
            This Agreement is effective until terminated. Worksense may
            terminate or suspend this Agreement or your use of the Service at
            any time and without prior notice, for any or no reason, including
            if Worksense believes that you have violated or acted inconsistently
            with the letter or spirit of this Agreement. Upon any such
            termination or suspension, your right to use the Service will
            immediately cease, and Worksense may, without liability to you or
            any third party, immediately deactivate or delete your user name,
            password and account, and all associated materials, without any
            obligation to provide any further access to such materials. Sections
            2 to 7 to 10 and 12 to 24 will survive any expiration or termination
            of this Agreement.
          </p>
          <h2 className="text-2xl text-[#14576E] mt-6">
            18. Dispute Resolution, Arbitration, Class Action Waiver
          </h2>
          <p className="text-black">
            You agree to resolve certain disputes with Worksense through binding
            arbitration (“<u>Arbitration Agreement</u>”). Arbitration means that
            an arbitrator, and not a judge or a jury, will decide the dispute.
            The parties expressly waive the right to bring or participate in any
            kind of class, collective, or mass action, private attorney general
            action, or any other representative action. This Arbitration
            Agreement supersedes all prior versions.
          </p>
          <h3 className="text-xl text-[#14576E] mt-4">
            18.1. Covered Disputes
          </h3>
          <p className="text-black">
            You and Worksense agree that any dispute or claim between you and
            Worksense arising out of or relating to this Agreement or the
            Services (a “<u>Dispute</u>”), including any related software,
            hardware, integrations, advertising or marketing communications,
            your account, or any aspects of your relationship or transactions
            with Worksense, will be resolved by binding arbitration, rather than
            in court. For purposes of this Arbitration Agreement, a Dispute will
            also include disputes that arose or involve facts occurring before
            the existence of this or any prior versions of this Agreement as
            well as claims that may arise after the termination of this
            Agreement. LAWSUITS AND ARBITRATIONS MAY BE FILED, WHICH COULD
            AFFECT YOU. YOUR AGREEMENT TO THIS ARBITRATION AGREEMENT COULD
            AFFECT YOUR PARTICIPATION IN THOSE ACTIONS.
          </p>
          <h3 className="text-xl text-[#14576E] mt-4">
            18.2. Exceptions to Arbitration
          </h3>
          <p className="text-black">
            This Arbitration Agreement will not require arbitration of the
            following types of claims brought by either you or Worksense: (i)
            small claims court actions, if the requirements of the court are met
            and the claims are only on an individual basis; and (ii) claims
            pertaining to intellectual property rights, including trademarks,
            trade dress, domain names, trade secrets, copyrights, and patents.
          </p>
          <h3 className="text-xl text-[#14576E] mt-4">
            18.3. Informal Dispute Resolution First
          </h3>
          <p className="text-black">
            18.3. Informal Dispute Resolution First. We want to address any
            Disputes without needing arbitration. If you have a Dispute with
            Worksense, prior to initiating arbitration, you agree to mail an
            individualized request (“<u>re-Arbitration Demand</u>”) to ATTN:
            to arshan@worksenseai.com so that we can work together to
            resolve the Dispute. A Pre-Arbitration Demand is only valid when it
            pertains to, and is on behalf of, a single individual. A
            Pre-Arbitration Demand brought on behalf of multiple individuals is
            invalid as to all. The Pre-Arbitration Demand must include: (i) your
            name, telephone number, mailing address, and email address
            associated with your account; (ii) the name, telephone number,
            mailing address and email address of your counsel, if any; (iii) a
            description of your Dispute; and (iv) your signature. Likewise, if
            Worksense has a Dispute with you, Worksense will send an email with
            its individualized Pre-Arbitration Demand, including the
            requirements listed above, to the email address associated with your
            Worksense account. If the Dispute is not resolved within sixty (60)
            calendar days of when either you or Worksense submitted a
            Pre-Arbitration Demand, an arbitration can be brought. If you or
            Worksense have a Dispute involving claims under the exception to
            arbitration in Section 18.2, then this Section 18.3 does not apply
            to such Dispute. You agree that compliance with this Section 24.3 is
            a condition precedent to commencing arbitration, and that the
            arbitrator will dismiss any arbitration filed without fully and
            completely complying with these informal dispute resolution
            procedures.
          </p>
          <h3 className="text-xl text-[#14576E] mt-4">
            18.4. Arbitration Procedure
          </h3>
          <p className="text-black">
            If, after completing the informal dispute resolution process set out
            in Section 24.3, either you or Worksense wishes to initiate
            arbitration, the initiating party must serve the other party with a
            demand for arbitration. Any demand for arbitration by you will be
            sent to the Worksense address in Section 24.3. Worksense will send
            any arbitration demand to the email address associated with your
            Worksense account or to your counsel, if any. You and Worksense
            agree that the Federal Arbitration Act (“<u>FAA</u>”) governs this
            Arbitration Agreement. If the FAA cannot apply for whatever reason,
            then the state laws governing arbitration procedures where you
            reside apply.
          </p>
          <p className="text-black">
            The applicable arbitration provider depends on where you live. If
            you are a California resident, the arbitration will be administered
            by ADR Services, Inc. (“<u>ADR Services</u>”) under its Arbitration
            Rules, available at
            https://www.adrservices.com/services-2/arbitration-rules. If you are
            not a California resident, the arbitration will be administered by
            National Arbitration and Mediation (“<u>NAM</u>”) under its
            operative Comprehensive Dispute Resolution Rules and Procedures,
            available at https://www.namadr.com/resources/rules-fees-forms. This
            Agreement will govern to the extent it conflicts with the
            arbitration provider’s rules. If the applicable arbitration provider
            is not available to arbitrate, the parties will select an
            alternative arbitration provider. If the parties cannot agree on an
            appropriate alternative arbitration provider, then the parties will
            ask a court of competent jurisdiction to appoint an arbitrator
            pursuant to 9 U.S.C. § 5. To the extent there is a dispute over
            which arbitration provider has jurisdiction, a NAM arbitrator will
            be appointed to resolve that dispute.
          </p>
          <p className="text-black">
            Arbitration hearings will take place through videoconferencing by
            default, unless you and Worksense agree upon another location in
            writing. A single arbitrator will be appointed. The arbitrator may
            award damages, declaratory or injunctive relief, and recoverable
            costs. Any arbitration award may be enforced (such as through a
            judgment) in any court with jurisdiction. An arbitration award will
            have no preclusive effect in another arbitration or court proceeding
            involving Worksense and a different individual. The arbitrator will
            have the exclusive authority to resolve all threshold arbitrability
            issues, including whether this Agreement is applicable,
            unconscionable, or enforceable, as well as any defense to
            arbitration. However, a court has exclusive authority to rule on the
            Mass Action Waiver in Section 18.6, including any claim that the
            section is unenforceable, illegal, void or voidable, or that it has
            been breached.
          </p>
          <p className="text-black">
            If a request to proceed in small claims court (see Section 18.2), is
            made after an arbitration has been initiated, but before an
            arbitrator has been appointed, such arbitration will be
            administratively closed. Any controversy over the small claims
            court’s jurisdiction will be determined by the small claims court.
            If you or Worksense challenges the small claims court election in
            your Dispute, and a court of competent jurisdiction determines that
            the small claims court election is unenforceable, then such election
            will be severed from this Arbitration Agreement as to your Dispute.
            However, such court determination will have no preclusive effect in
            another arbitration or court proceeding involving Worksense and a
            different individual.
          </p>

          <h3 className="text-xl text-[#14576E] mt-4">
            18.5. Jury Trial Waiver
          </h3>
          <p className="text-black">
            You and Worksense hereby waive any constitutional and statutory
            rights to sue in court and have a trial in front of a judge or a
            jury. You and Worksense are instead electing that all Disputes will
            be resolved by arbitration under this Arbitration Agreement, except
            as specified in Section 18.2 above. Court review of an arbitration
            award is subject to very limited review. Discovery may be limited in
            arbitration, and procedures are more streamlined than in court.
          </p>

          <h3 className="text-xl text-[#14576E] mt-4">
            18.6. Mass Action Waiver
          </h3>
          <p className="text-black">
            You and Worksense agree that, except as specified in Section 18.7
            below, each of us may bring claims against the other only on an
            individual basis and not on a class, collective, representative, or
            mass action basis, and the parties hereby waive all rights to have
            any Dispute be brought, heard, administered, resolved, or arbitrated
            on a class, collective, representative, or mass action basis.
            Subject to this Arbitration Agreement, the arbitrator may award
            declaratory or injunctive relief only in favor of the individual
            party seeking relief and only to the extent necessary to provide
            relief warranted by the party’s individual claim. Notwithstanding
            anything to the contrary in this Arbitration Agreement, if a court
            decides by means of a final decision, not subject to any further
            appeal or recourse, that the limitations of this Section 18.6 are
            invalid or unenforceable as to a particular claim or request for
            relief (such as a request for public injunctive relief), you and
            Worksense agree that that particular claim or request for relief
            (and only that particular claim or request for relief) will be
            severed from the arbitration and will be pursued in the state or
            federal courts located in California. This subsection does not
            prevent you or Worksense from participating in a class-wide
            settlement of claims.
          </p>

          <h3 className="text-xl text-[#14576E] mt-4">
            18.7. Bellwether Arbitrations
          </h3>
          <p className="text-black">
            To increase the efficiency of administration and resolution of
            arbitrations, you and Worksense agree that if there are fifty (50)
            or more individual arbitration demands of a substantially similar
            nature brought against either party by or with the assistance of the
            same law firm, group of law firms, or organizations within a one
            hundred and eighty (180) day period (“<u>Mass Filing</u>”), the
            parties will select sixteen (16) individual arbitration demands
            (eight (8) per side) for arbitration to proceed (“Bellwether
            Arbitrations”). Only those sixteen (16) arbitration demands will be
            filed with the arbitration provider, and the parties will hold in
            abeyance, and not file, the non-Bellwether Arbitrations. Worksense
            will pay the arbitration provider’s costs for the sixteen (16)
            Bellwether Arbitrations. The statutes of limitation, including the
            requirement to file within one (1) year in Section 18.10 below, will
            remain tolled when non-Bellwether arbitration demands are held in
            abeyance. While the Bellwether Arbitrations are adjudicated, no
            other demand for arbitration that is part of the Mass Filing may be
            processed, administrated, or adjudicated, and no filing or other
            administrative costs for such a demand for arbitration will be due
            from either party to the arbitration provider. If, contrary to this
            provision, a party prematurely files non-Bellwether Arbitrations
            with the arbitration provider, the parties agree that the
            arbitration provider will hold those demands in abeyance.
          </p>

          <p className="text-black">
            All parties agree that arbitration demands are of a “substantially
            similar nature” if they arise out of or relate to the same event or
            factual scenario and raise the same or similar legal issues and seek
            the same or similar relief. Any party may request that the
            arbitration provider appoint a sole standing administrative
            arbitrator (“<u>Administrative Arbitrator</u>”) to determine
            threshold questions such as (i) whether the Bellwether Arbitration
            process is applicable or enforceable, (ii) whether particular
            demand(s) are part of a Mass Filing, and (iii) whether demands
            within a Mass Filing were filed in accordance with this Agreement,
            including Section 18.3. In an effort to expedite resolution of any
            such dispute by the Administrative Arbitrator, the parties agree
            that the Administrative Arbitrator may set forth such procedures as
            are necessary to resolve any disputes promptly. The Administrative
            Arbitrator’s costs will be paid by Worksense.
          </p>
          <p className="text-black">
            The parties will work in good faith with the arbitrator to complete
            each Bellwether Arbitration within one hundred and twenty (120)
            calendar days of its initial pre-hearing conference. The parties
            agree that the Bellwether Arbitration process is designed to achieve
            an overall faster, more efficient, and less costly mechanism for
            resolving Mass Filings, including the claims of individuals who are
            not selected for a Bellwether Arbitration.
          </p>
          <p className="text-black">
            Following resolution of the Bellwether Arbitrations, the parties
            agree to engage in a global mediation of all remaining arbitration
            demands comprising the Mass Filing (“<u>Global Mediation</u>”). The
            Global Mediation will be administered by the arbitration provider
            administering the Bellwether Arbitrations. If the parties are unable
            to resolve the remaining demands for arbitration comprising the Mass
            Filing within thirty (30) calendar days following the mediation, the
            remaining demands for arbitration comprising the Mass Filing will be
            filed and administered by the arbitration provider on an individual
            basis pursuant to the arbitration provider’s rules, unless the
            parties mutually agree otherwise in writing. Any party may request
            that the arbitration provider appoint an Administrative Arbitrator
            to determine threshold questions regarding the newly filed demands.
          </p>
          <p className="text-black">
            The parties agree to cooperate in good faith with the arbitration
            provider to implement the Bellwether Arbitration process, including
            the payment of filing and administrative costs for the Bellwether
            Arbitrations, deferring any filing costs associated with the
            non-Bellwether Arbitration Mass Filings until the Bellwether
            Arbitrations and subsequent Global Mediation have concluded, and
            cooperate on any steps to minimize the time and costs of
            arbitration, which may include: (i) the appointment of a discovery
            special master to assist the arbitrator in the resolution of
            discovery disputes; and (ii) the adoption of an expedited calendar
            of the arbitration proceedings. This Bellwether Arbitration
            provision will in no way be interpreted as authorizing a class,
            collective, or mass action of any kind, or an arbitration involving
            joint or consolidated claims under any circumstances, except as
            expressly set forth in this provision. The statutes of limitation
            applicable to each arbitration demand within a Mass Filing,
            including the requirement to file within one (1) year in Section
            18.10 below, will remain tolled from the time a party makes a
            Pre-Arbitration Demand to the time when that party files the
            arbitration demand with the arbitration provider.
          </p>
          <h3 className="text-xl text-[#14576E] mt-4">
            18.8. Settlement Offers and Offers of Judgment.
          </h3>
          <p className="text-black">
            At least ten (10) calendar days before the date set for the
            arbitration hearing, you or Worksense may serve a written offer of
            judgment upon the other party to allow judgment on specified terms.
            If the offer is accepted, the offer with proof of acceptance will be
            submitted to the arbitration provider, who will enter judgment
            accordingly. If the offer is not accepted prior to the arbitration
            hearing or within thirty (30) calendar days after it is made,
            whichever occurs first, it will be deemed withdrawn, and cannot be
            given as evidence in the arbitration. If an offer made by one party
            is not accepted by the other party, and the other party fails to
            obtain a more favorable award, the other party will not recover
            their post-offer costs and will pay the offering party’s costs from
            the time of the offer (which, solely for purposes of offers of
            judgment, may include reasonable attorneys’ fees to the extent they
            are recoverable by statute, in an amount not to exceed the damages
            awarded).
          </p>
          <p className="text-black">
            The parties agree that any disputes with respect to settlement
            offer(s) or offer(s) of judgment in a Mass Filing are to be resolved
            by a single arbitrator to the extent such offers contain the same
            material terms. For arbitrations involving represented parties, the
            represented parties’ attorneys agree to communicate individual
            settlement offer(s) or offer(s) of judgment to each and every
            arbitration claimant or respondent to whom such offers are extended.
          </p>
          <h3 className="text-xl text-[#14576E] mt-4">
            18.9. Arbitration Costs
          </h3>
          <p className="text-black">
            Except as provided for in a Mass Filing (see Section 18.7), your
            responsibility to pay any filing, administrative, and arbitrator
            costs will be solely as set forth in the applicable arbitration
            provider’s rules. If you have a gross monthly income of less than
            300% of the federal poverty guidelines, you may be entitled to a
            waiver of certain arbitration costs.
          </p>
          <h3 className="text-xl text-[#14576E] mt-4">
            18.10. Requirement to File Within One Year
          </h3>
          <p className="text-black">
            To the extent permitted by applicable law, and notwithstanding any
            other statute of limitations, any claim or cause of action under
            this Agreement (with the exception of disputes under Section
            18.2(2)) must be filed within one (1) year after such claim or cause
            of action arose, or else that claim or cause of action will be
            permanently barred. The statute of limitations and any arbitration
            cost deadlines will be tolled while the parties engage in the
            informal dispute resolution process required by Section 18.3 above.
          </p>
          <h3 className="text-xl text-[#14576E] mt-4">18.11. Opt-Out</h3>
          <p className="text-black">
            You may reject this Arbitration Agreement and opt out of arbitration
            by sending an email to arshan@worksenseai.com within thirty (30)
            calendar days after the date you created your account. Your opt-out
            notice must be individualized and must be sent from the email
            address associated with your individual account. An opt-out notice
            that purports to opt out multiple parties will be invalid as to all
            such parties. No individual (or their agent or representative) may
            effectuate an opt out on behalf of other individuals. Your notice to
            opt-out must include your first and last name, address, the email
            address associated with your account, and an unequivocal statement
            that you decline this Arbitration Agreement. If you do decide to opt
            out, that opt out will apply to this Arbitration Agreement, and
            neither party will have the right to compel the other to arbitrate
            any Dispute. However, all other parts of this Arbitration Agreement
            will continue to apply to you, and opting out of this Arbitration
            Agreement has no effect on any other arbitration agreements that you
            may enter into in the future with us.
          </p>
          <h3 className="text-xl text-[#14576E] mt-4">18.12. Severability</h3>
          <p className="text-black">
            Except as provided in Section 18.6 above, if any provision of this
            Arbitration Agreement is found to be illegal or unenforceable, then
            that provision will be severed; however, the remaining provisions
            will still apply and will be interpreted to achieve the closest
            possible intent to the original intent of this section, inclusive of
            the severed provision.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">19. Governing Law</h2>
          <p className="text-black">
            This Agreement is governed by and will be construed in accordance
            with the laws of the State of California, U.S.A., without regard to
            its principles of conflicts of law, and regardless of your location.
            For all disputes between you and us other than those governed by the
            Arbitration Agreement, if any, you and Worksense agree to exclusive
            jurisdiction of the federal and state courts located in Alameda,
            California, U.S.A., and waive any jurisdictional, venue or
            inconvenient forum objections to such courts.
          </p>
          <h2 className="text-2xl text-[#14576E] mt-6">20. Filtering</h2>
          <p className="text-black">
            We hereby notify you that parental control protections (such as
            computer hardware, software or filtering services) are commercially
            available that may assist you in limiting access to material that is
            harmful to minors. Information identifying current providers of such
            protections is available from
            https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers.
            Please note that Worksense does not endorse any of the products or
            services listed on such Service.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            21. Information or Complaints
          </h2>
          <p className="text-black">
            If you have a question or complaint regarding the Service, please
            send an e-mail to arshan@worksenseai.com. Please note that e-mail
            communications will not necessarily be secure; accordingly, you
            should not include credit card information or other sensitive
            information in your e-mail correspondence with us. California
            residents may reach the Complaint Assistance Unit of the Division of
            Consumer Services of the California Department of Consumer Affairs
            by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by
            telephone at (916) 445-1254 or (800) 952-5210.
          </p>
          <h2 className="text-2xl text-[#14576E] mt-6">22. Export Controls</h2>
          <p className="text-black">
            You are responsible for complying with United States export controls
            and for any violation of such controls, including any United States
            embargoes or other federal rules and regulations restricting
            exports. You represent, warrant and covenant that you are not (a)
            located in, or a resident or a national of, any country subject to a
            U.S. government embargo or other restriction, or that has been
            designated by the U.S. government as a “terrorist supporting”
            country; or (b) on any of the U.S. government lists of restricted
            end users.
          </p>
          <h2 className="text-2xl text-[#14576E] mt-6">23. Promotions</h2>
          <p className="text-black">
            Any sweepstakes, contests, raffles, surveys, games, or similar
            promotions (collectively, “<u>Promotions</u>”) made available through the
            Service may be governed by rules that are separate from this
            Agreement. If you participate in any Promotions, please review the
            applicable rules as well as our Privacy Policy. If the rules for a
            Promotion conflict with this Agreement, the Promotion rules will
            govern.
          </p>
          <h2 className="text-2xl text-[#14576E] mt-6">24. Miscellaneous</h2>
          <p className="text-black">
            This Agreement does not, and will not be construed to, create any
            partnership, joint venture, employer-employee, agency or
            franchisor-franchisee relationship between you and Worksense. If any
            provision of this Agreement is found to be unlawful, void or for any
            reason unenforceable, that provision will be deemed severable from
            this Agreement and will not affect the validity and enforceability
            of any remaining provision. You may not assign, transfer or
            sublicense any or all of your rights or obligations under this
            Agreement without our express prior written consent. We may assign,
            transfer or sublicense any or all of our rights or obligations under
            this Agreement without restriction. No waiver by either party of any
            breach or default under this Agreement will be deemed to be a waiver
            of any preceding or subsequent breach or default. Any heading,
            caption or section title contained herein is for convenience only,
            and in no way defines or explains any section or provision. All
            terms defined in the singular will have the same meanings when used
            in the plural, where appropriate and unless otherwise specified. Any
            use of the term “including” or variations thereof in this Agreement
            will be construed as if followed by the phrase “without limitation.”
            This Agreement, including any terms and conditions incorporated
            herein, is the entire agreement between you and Worksense relating
            to the subject matter hereof, and supersedes any and all prior or
            contemporaneous written or oral agreements or understandings between
            you and Worksense relating to such subject matter. Notices to you
            (including notices of changes to this Agreement) may be made via
            posting to the Service or by e-mail (including in each case via
            links), or by regular mail. Without limitation, a printed version of
            this Agreement and of any notice given in electronic form will be
            admissible in judicial or administrative proceedings based upon or
            relating to this Agreement to the same extent and subject to the
            same conditions as other business documents and records originally
            generated and maintained in printed form. Worksense will not be
            responsible for any failure to fulfill any obligation due to any
            cause beyond its control.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TermsOfService;
