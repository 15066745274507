const prod = {
  apiBaseUrl: 'https://',
  functionSuffix: '-vk5ngmgetq-uc.a.run.app'
};

const dev = {
  apiBaseUrl: 'http://127.0.0.1:5001/worksense-c6b70/us-central1',
  functionSuffix: ''
};

export const config = process.env.REACT_APP_ENV === 'production' ? prod : dev;

export const getApiUrl = (functionName) => {
  if (process.env.REACT_APP_ENV === 'production') {
    // Use dashes for production (as is)
    return `${config.apiBaseUrl}${functionName}${config.functionSuffix}`;
  }
  
  // Replace dashes with underscores for development
  const devFunctionName = functionName.replace(/-/g, '_');
  return `${config.apiBaseUrl}/${devFunctionName}`;
};

export const isProduction = () => process.env.REACT_APP_ENV === 'production';