import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import * as Constants from "../utils/Constants";
import { getSelectedCompanyId } from "../utils/companyStorage";
import validatePayment from "../utils/ValidatePayment";
import { checkSandBoxStatus } from "../utils/checkSandboxStatus";

const useAuthenticatedUser = () => {
  const [user, setUser] = useState(null);
  const [sandboxUserQueryParameter, setSandboxUserQueryParameter] =
    useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const authenticateUser = async () => {
      onAuthStateChanged(auth, async (firebaseUser) => {
        if (firebaseUser) {
          try {
            const companyId = await getSelectedCompanyId(firebaseUser.uid);
            const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, companyId);
            const userDocSnapshot = await getDoc(userDocRef);
            const userData = userDocSnapshot.data();
            const isSandboxUser = checkSandBoxStatus(companyId);

            if (isSandboxUser) {
              setSandboxUserQueryParameter("&demo=True");
            }

            setUser({
              firebaseUser: firebaseUser,
              uid: companyId,
              displayName: firebaseUser.displayName,
              email: firebaseUser.email,
              isDemoUser: userData?.isDemoUser ?? true,
              userTier: userData?.userTier ?? true,
              isSandboxUser: isSandboxUser,
            });

            // Validate payment and fetch recommendations
            const hasPaid = await validatePayment(firebaseUser.uid);
            if (!hasPaid) {
              window.location.href = "/onboarding";
            } else {
              setIsLoading(false);
            }
          } catch (error) {
            console.error(
              "Error during authentication and data fetching:",
              error
            );
          }
        } else {
          window.location.href = "/signin";
        }
      });
    };

    authenticateUser();
  }, []);

  return { user, sandboxUserQueryParameter, isLoading };
};

export default useAuthenticatedUser;
