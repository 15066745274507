import React, { useState, useEffect } from "react";
import Header from "./Header";
import WorksenseLogo from "../images/logos/worksenselogo2.png";
import { auth } from "../firebase";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  BookOpenIcon,
  BoltIcon,
  Squares2X2Icon,
  ChartBarIcon,
  Square3Stack3DIcon,
  UsersIcon,
  UserIcon,
  UserPlusIcon,
  ClockIcon,
  MagnifyingGlassIcon,
  LightBulbIcon,
  CurrencyDollarIcon,
  QueueListIcon,
} from "@heroicons/react/24/outline";
import {
  getSelectedCompanyId,
  setSelectedCompanyId,
} from "../utils/companyStorage";
import { onAuthStateChanged } from "firebase/auth";
import HRISIntegrationAlert from "../components/GenericDashboardComponents/HRISIntegrationAlert";
import { getApiUrl } from "../utils/apiConfig";
import { checkSandBoxStatus } from "../utils/checkSandboxStatus";
import * as Constants from "../utils/Constants";

const Layout = ({ children }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    getSelectedCompanyId()
  );
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [userData, setUserData] = useState({ displayName: "", email: "" });
  const [totalDataPoints, setTotalDataPoints] = useState(null);
  const [sandboxUserQueryParameter, setSandboxUserQueryParameter] = useState(
    ""
  );
  const [isDemoUser, setIsDemoUser] = useState(false);
  const [userTier, setUserTier] = useState(null);
  const [isPeopleExpanded, setIsPeopleExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCompanies = async () => {
      const db = getFirestore();
      const companiesRef = collection(db, "companies");
      const snapshot = await getDocs(companiesRef);
      const companiesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setCompanies(companiesData);
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoading(true);
      if (user) {
        setIsAdminUser(user.uid === "4KISCpZsWBd2xinV7d4M1P9kZFq2");
        setUserData({ displayName: user.displayName, email: user.email });
        const isSandboxUser = checkSandBoxStatus(user.uid);

        if (isSandboxUser) {
          setSandboxUserQueryParameter("&demo=True");
        }

        const companyId = await getSelectedCompanyId(user.uid);

        getTotalDataPoints(companyId);
        if (companyId) {
          const db = getFirestore();
          const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, companyId);
          const userDocSnapshot = await getDoc(userDocRef);
          const userData = userDocSnapshot.data();
          setIsDemoUser(userData?.isDemoUser ?? false);
          setUserTier(userData?.userTier ?? null);
        }
      } else {
        console.log("No authenticated user");
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const shouldShowFeature = (feature) => {
    switch (feature) {
      case "highPerformers":
        return isDemoUser || ["Pro", "Elite"].includes(userTier);
      case "realTimePerformers":
        return isDemoUser || userTier === "Elite";
      case "orgChart":
        return isDemoUser;
      default:
        return false;
    }
  };

  const handleCompanyChange = (event) => {
    const companyId = event.target.value;
    setSelectedCompany(companyId);
    setSelectedCompanyId(companyId);
    window.location.href = "/dashboard";
  };

  const getTotalDataPoints = async (companyId) => {
    const idToken = await auth.currentUser.getIdToken();
    const endpointUrl = getApiUrl("get-total-data-points");
    const url = `${endpointUrl}?company_uuid=${companyId}${sandboxUserQueryParameter}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      const data = await response.json();
      setTotalDataPoints(data);
      console.log(totalDataPoints);
    } catch (error) {
      console.error("Error fetching processed messages:", error);
    }
  };

  return (
    <div data-theme="emerald" className="drawer drawer-mobile bg-base-200">
      <input
        id="left-sidebar-drawer"
        type="checkbox"
        className="drawer-toggle"
      />

      <div
        data-theme="emerald"
        className="drawer-content flex flex-col min-h-screen"
      >
        <Header
          data-theme="emerald"
          userDisplayName={userData.displayName}
          userEmail={userData.email}
        />
        {totalDataPoints && (
          <div className="px-6 pt-6">
            <HRISIntegrationAlert totalDataPoints={totalDataPoints} />
          </div>
        )}
        <main data-theme="emerald" className="flex-1 overflow-y-auto pt-8 px-6">
          {children}
        </main>
      </div>

      <div className="drawer lg:drawer-open">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col"></div>
        <div className="drawer-side z-20">
          <label
            htmlFor="my-drawer-2"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <ul className="menu pt-2 w-80 bg-base-200 min-h-screen">
            <li className="mb-2 font-semibold text-xl hover:bg-gray-300">
              <a href={"/dashboard"}>
                <img
                  className="mask mask-square w-12"
                  src={WorksenseLogo}
                  alt="Worksense Logo"
                />
                Worksense
              </a>
            </li>
            <li className="font-semibold text-xl">
              <a href="/dashboard">
                <div className="flex">
                  <Squares2X2Icon className={"h-7 w-7 pr-2 base-100"} />{" "}
                  Dashboard
                </div>
              </a>
            </li>
            <li className="ml-4 mb-2 text-xl">
              <a href="/heatmap">
                <div className="flex">
                  <Square3Stack3DIcon className={"h-7 w-7 pr-2 base-100"} />{" "}
                  Heatmap
                </div>
              </a>
            </li>
            <li className="ml-4 mb-2 text-xl">
              <a href="/teamdata">
                <div className="flex">
                  <UsersIcon className={"h-7 w-7 pr-2 base-100"} /> Team Data
                </div>
              </a>
            </li>
            <li className="ml-4 mb-2 text-xl">
              <a href="/timeseries">
                <div className="flex">
                  <ClockIcon className={"h-7 w-7 pr-2 base-100"} /> Time Series
                </div>
              </a>
            </li>
            <li className="mb-2 font-semibold text-xl">
              <a href="/insights">
                <div className="flex">
                  <ChartBarIcon className={"h-7 w-7 pr-2 base-100"} /> Insights
                </div>
              </a>
            </li>
            <li className="ml-4 mb-2 text-xl">
              <a href="/recommendations">
                <div className="flex">
                  <LightBulbIcon className={"h-7 w-7 pr-2 base-100"} />{" "}
                  Recommendations
                </div>
              </a>
            </li>
            {isDemoUser && (
              <li className="ml-4 mb-2 text-xl">
                <a href="/costsavings">
                  <div className="flex">
                    <CurrencyDollarIcon className={"h-7 w-7 pr-2 base-100"} />{" "}
                    Cost Savings
                  </div>
                </a>
              </li>
            )}

            {!isLoading &&
              (isDemoUser ||
                shouldShowFeature("highPerformers") ||
                shouldShowFeature("realTimePerformers")) && (
                <>
                  <li className="font-semibold text-xl">
                    <button
                      onClick={() => setIsPeopleExpanded(!isPeopleExpanded)}
                      className="flex w-full"
                    >
                      <div className="flex">
                        <UserIcon className={"h-7 w-7 pr-2 base-100"} /> People
                      </div>
                    </button>
                  </li>
                  {isPeopleExpanded && (
                    <>
                      {isDemoUser && (
                        <li className="ml-4 mb-2 text-xl">
                          <a href="/org-chart">
                            <div className="flex">
                              <QueueListIcon
                                className={"h-7 w-7 pr-2 base-100"}
                              />{" "}
                              Organization Chart
                            </div>
                          </a>
                        </li>
                      )}
                      {shouldShowFeature("highPerformers") && (
                        <li className="ml-4 mb-2 text-xl">
                          <a href="/highperformers">
                            <div className="flex">
                              <UserPlusIcon
                                className={"h-7 w-7 pr-2 base-100"}
                              />{" "}
                              High Performers
                            </div>
                          </a>
                        </li>
                      )}
                      {shouldShowFeature("realTimePerformers") && (
                        <li className="ml-4 mb-2 text-xl">
                          <a href="/realtimeperformers">
                            <div className="flex">
                              <MagnifyingGlassIcon
                                className={"h-7 w-7 pr-2 base-100"}
                              />{" "}
                              Real Time Performers
                            </div>
                          </a>
                        </li>
                      )}
                    </>
                  )}
                </>
              )}

            <li className="mb-2 font-semibold text-xl">
              <a href="/integrations">
                <div className="flex">
                  <BoltIcon className={"h-7 w-7 pr-2 base-100"} /> Integrations
                </div>
              </a>
            </li>
            <li className="mb-2 font-semibold text-xl">
              <a href="/documentation">
                <div className="flex">
                  <BookOpenIcon className={"h-7 w-7 pr-2 base-100"} />{" "}
                  Documentation
                </div>
              </a>
            </li>
            {isAdminUser && (
              <form className="mb-2 font-semibold text-xl ">
                <label
                  htmlFor="companies"
                  className="block text-center mb-2 font-semibold text-xl"
                >
                  Select User View
                </label>
                <select
                  id="companies"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={handleCompanyChange}
                  value={selectedCompany}
                >
                  <option value="" disabled>
                    Select a Company
                  </option>
                  {companies
                    .filter((company) => company.email)
                    .map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.email} - {company.id}
                      </option>
                    ))}
                </select>
              </form>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Layout;
