import React, { useState } from 'react';
import PricingCard from './PricingCard'; // Import your PricingCard component

const PricingCardList = ({ numberOfEmployees, onPlanSelect }) => {
  const [selectedPlan, setSelectedPlan] = useState({ id: null, type: null });

  // Function to handle plan selection
  const handlePlanSelect = (id, type) => {
    setSelectedPlan({ id, type });
    
    // Log the details of the selected plan
    console.log('Selected Plan Details:', {
      id,
      type,
      plans: getFilteredPlans().find(plan => plan.id === id && plan.type === type)
    });

    if (onPlanSelect) onPlanSelect(id, type); // Notify parent component of selection
  };

  // Function to get filtered plans based on number of employees
  const getFilteredPlans = () => {
    const pricingOptions = [
      {
        type: 'Monthly',
        plans: [
          { id: 1, employees: '1-50', price: '$300/month', annualPrice: '$3,600/year' },
          { id: 2, employees: '51-100', price: '$600/month', annualPrice: '$7,200/year' },
          { id: 3, employees: '101-200', price: '$1,200/month', annualPrice: '$14,400/year' },
          { id: 4, employees: '201-300', price: '$1,800/month', annualPrice: '$21,600/year' },
          { id: 5, employees: '301-400', price: '$2,300/month', annualPrice: '$27,600/year' },
          { id: 6, employees: '401-500', price: '$2,700/month', annualPrice: '$32,400/year' },
          { id: 7, employees: '501-600', price: '$3,200/month', annualPrice: '$38,400/year' },
          { id: 8, employees: '601-700', price: '$3,700/month', annualPrice: '$44,400/year' },
          { id: 9, employees: '701-800', price: '$4,200/month', annualPrice: '$50,400/year' },
          { id: 10, employees: '801-900', price: '$4,600/month', annualPrice: '$55,200/year' },
          { id: 11, employees: '901-1000', price: '$5,000/month', annualPrice: '$60,000/year' }
        ]
      },
      {
        type: 'Annually',
        plans: [
          { id: 1, employees: '1-50', price: '$240/month', annualPrice: '$2,880/year' },
          { id: 2, employees: '51-100', price: '$480/month', annualPrice: '$5,760/year' },
          { id: 3, employees: '101-200', price: '$960/month', annualPrice: '$11,520/year' },
          { id: 4, employees: '201-300', price: '$1,440/month', annualPrice: '$17,280/year' },
          { id: 5, employees: '301-400', price: '$1,840/month', annualPrice: '$22,080/year' },
          { id: 6, employees: '401-500', price: '$2,160/month', annualPrice: '$25,920/year' },
          { id: 7, employees: '501-600', price: '$2,560/month', annualPrice: '$30,720/year' },
          { id: 8, employees: '601-700', price: '$2,960/month', annualPrice: '$35,520/year' },
          { id: 9, employees: '701-800', price: '$3,360/month', annualPrice: '$40,320/year' },
          { id: 10, employees: '801-900', price: '$3,680/month', annualPrice: '$44,160/year' },
          { id: 11, employees: '901-1000', price: '$4,000/month', annualPrice: '$48,000/year' }
        ]
      }
    ];

    if (numberOfEmployees === '1001+') {
      return null; // Indicate to show contact sales message
    }

    const employeeCount = parseInt(numberOfEmployees.split('-')[0]);

    const monthlyPlan = pricingOptions[0].plans.find(plan => {
      const [min, max] = plan.employees.split('-').map(Number);
      return employeeCount >= min && employeeCount <= max;
    });

    const annualPlan = pricingOptions[1].plans.find(plan => {
      const [min, max] = plan.employees.split('-').map(Number);
      return employeeCount >= min && employeeCount <= max;
    });


    return [
      monthlyPlan ? { ...monthlyPlan, type: 'Monthly' } : null,
      annualPlan ? { ...annualPlan, type: 'Annually' } : null
    ].filter(Boolean);
  };

  const filteredPlans = getFilteredPlans();

  return (
    <div className="pricing-card-list">
      {numberOfEmployees === '1001+' ? (
        <div className="contact-sales">
          <p>For 1001+ employees, please <a href="mailto:sales@example.com">contact sales</a> for a custom quote.</p>
        </div>
      ) : (
        <div className="flex flex-wrap gap-4 justify-center p-8">
          {filteredPlans.length > 0 ? (
            filteredPlans.map(plan => (
              <PricingCard
                key={`${plan.id}-${plan.type}`} // Unique key based on id and type
                id={plan.id}
                employees={plan.employees}
                price={plan.price}
                annualPrice={plan.annualPrice}
                type={plan.type}
                isSelected={selectedPlan.id === plan.id && selectedPlan.type === plan.type}
                onSelect={() => handlePlanSelect(plan.id, plan.type)}
              />
            ))
          ) : (
            <div className="text-center text-lg text-gray-600">
              <p>No plans available for the selected number of employees.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PricingCardList;
