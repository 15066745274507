import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Logo from "../images/logos/WorksenseBigger.png"; // Ensure this is the correct logo file
import { AnimatedBackground, SparkleEffect } from "../utils/Animations";
import { doc, setDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import { getSelectedCompanyId } from "../utils/companyStorage";
import {
  containerVariants,
  itemVariants,
} from "../components/OnboardingComponents/AnimationsVariants";

const OnboardingSuccessPage = () => {
  const handleCompleteOnboarding = async () => {
    try {
      const userUid = await getSelectedCompanyId(auth.currentUser.uid);
      const userDocRef = doc(db, "companies", userUid);
      await setDoc(
        userDocRef,
        {
          isOnboarded: true,
          isPaid: true,
        },
        { merge: true }
      );
      // After setting isPaid to true, redirect to dashboard
      window.location.href = "/dashboard";
    } catch (error) {
      console.log("Error completing the onboarding");
    }
  };

  return (
    <div className="bg-gradient-to-tr from-cyan-500 via-blue-400 to-blue-700 min-h-screen flex flex-col items-center relative">
      <AnimatedBackground />
      <div className="flex flex-col items-center justify-center w-full max-w-screen-xl px-6 py-6">
        <div className="flex-none mb-8">
          <img src={Logo} alt="Logo" className="h-24 w-auto" /> {/* Match logo size */}
        </div>
        <motion.div
          className="bg-white rounded-lg p-10 max-w-xl w-full z-10 flex flex-col"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{
            boxShadow:
              "0 20px 40px rgba(0, 0, 0, 0.4), 0 8px 16px rgba(0, 0, 0, 0.3)",
            minHeight: "auto",
            position: "relative",
          }}
        >
          <div className="flex flex-col flex-grow">
            <div className="flex-grow">
              <motion.h2
                className="text-3xl font-semibold mb-6 text-center text-blue-800"
                variants={itemVariants}
              >
                Congratulations!
              </motion.h2>
              <motion.p
          className="text-gray-700 mb-8 text-center text-lg"
          variants={itemVariants}
        >
          You’re all set to transform your business.
          <br />
          Dive in and start exploring the powerful tools and features we’ve tailored just for you.
          <br />
          Begin your journey now by clicking below to access your dashboard and start leveraging our platform.
        </motion.p>
              <motion.div className="flex justify-center" variants={itemVariants}>
                <button
                  className="px-8 py-3 bg-gradient-to-r from-blue-500 to-teal-500 text-white rounded-lg font-semibold text-lg shadow-lg hover:shadow-xl focus:shadow-xl transition duration-600 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  onClick={handleCompleteOnboarding}
                >
                  Go to Dashboard
                </button>
              </motion.div>
            </div>
          </div>
          <SparkleEffect />
        </motion.div>
      </div>
    </div>
  );
};

export default OnboardingSuccessPage;
