import React, { useEffect, useState, useCallback } from "react";
import { doc, getDoc } from "firebase/firestore";
import TitleCard from "../components/Cards/TitleCard";
import Layout from "../containers/Layout";
import { auth, db } from "../firebase";
import "../index.css";
import Loader from "../utils/Loader";
import LanguagePreferences from "../components/ProfilePage/LanguagePreferences";
import CompanyInfo from "../components/ProfilePage/CompanyInfo";
import { readOnlyStyle } from "../styles/ProfileStyles";
import EditButton from "../components/ProfilePage/EditButton";
import { getSelectedCompanyId } from "../utils/companyStorage";
import MessagesComponent from "../components/ProfilePage/MessagesComponent";
import validatePayment from "../utils/ValidatePayment";
import * as Constants from "../utils/Constants";

function Profile() {
  const [userEmail, setUserEmail] = useState("");
  const [userDisplayName, setUserDisplayName] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState("");
  const [currentUserUid, setCurrentUserUid] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      fetchUserData(firebaseUser);
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = useCallback(async (firebaseUser) => {
    if (firebaseUser) {
      const userUid = await getSelectedCompanyId(firebaseUser.uid);
      setCurrentUserUid(userUid);

      const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, userUid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        setUserEmail(userData.email);
        setUserDisplayName(userData.companyName);
        setCompanyDomain(userData.companyDomain);
        setNumberOfEmployees(userData.numberOfEmployees);
        setStripeCustomerId(userData.stripe_customer_id || "");
      } else {
        console.error("No user data found for UID " + userUid);
      }

      const hasPaid = await validatePayment(firebaseUser.uid);
      if (!hasPaid) {
        window.location.href = "/onboarding";
      } else {
        setIsLoading(false);
      }
    } else {
      window.location.href = "/signin";
    }
  }, []);

  const handleEditClick = (section) => {
    setIsEditing(true);
    setActiveSection(section);
    setErrorMessage(null);
  };

  const handleUpdateClick = () => {
    setIsEditing(false);
    setActiveSection(null);
  };

  const handleExitClick = () => {
    setIsEditing(false);
    setActiveSection(null);
  };

  const handleManageSubscription = () => {
    if (stripeCustomerId) {
      window.location.href = `https://billing.stripe.com/p/login/28o7sE7PF1KD6mA144?prefilled_email=${encodeURIComponent(userEmail)}`;
    } else {
      setErrorMessage(`No Stripe account found for ${userEmail}. There is no associated Stripe customer ID. If you think this is an error, please contact the Worksense team.`);
    }
  };

  return (
    <Layout userDisplayName={userDisplayName} userEmail={userEmail}>
      <MessagesComponent userUid={currentUserUid} isLoading={isLoading} />

      <Loader
        loading={isLoading}
        size={50}
        color={"#123abc"}
        loadingText={"Loading Profile"}
      >
        {errorMessage && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
            <p className="font-bold">Error</p>
            <p>{errorMessage}</p>
          </div>
        )}

        <TitleCard title="Company Profile" topMargin="mt-2">
          <CompanyInfo
            userEmail={userEmail}
            name={userDisplayName}
            domain={companyDomain}
            numberOfEmployees={numberOfEmployees}
            isEditing={isEditing}
            activeSection={activeSection}
            handleEditClick={handleEditClick}
            readOnlyStyle={readOnlyStyle}
          />

          <LanguagePreferences
            isEditing={isEditing}
            activeSection={activeSection}
            handleEditClick={handleEditClick}
            readOnlyStyle={readOnlyStyle}
          />

          <EditButton
            isEditing={isEditing}
            handleUpdateClick={handleUpdateClick}
            handleExitClick={handleExitClick}
          />
        </TitleCard>

        <TitleCard title="Stripe Subscription Management" topMargin="mt-4">
          <button
            onClick={handleManageSubscription}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Manage Subscription
          </button>
        </TitleCard>
      </Loader>
    </Layout>
  );
}

export default Profile;